import { Spinner } from 'reactstrap';

const LoaderSpin = (props) => {

const spinnerToggle = props.isVisible ? 'show' : 'd-none';

  return (
    <>
      <div className={`modal-backdrop fade ${spinnerToggle}`}>
          <div className="position-absolute top-50 start-50 translate-middle">
          <Spinner color="primary"  />
          </div>
      </div>
    </>
  );
};

export default LoaderSpin;
