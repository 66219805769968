import { createSlice } from "@reduxjs/toolkit"
//Viene preparata una struttura renderizzata dal ToastContainer
const initialState = {
    totalItemMessageToast : 0,        //numero globale messaggi in attesa di ricevere esito dalla ws
    totalRequestMessageToastId : '',  //requestId  concatenati e separati dal ;  --> occorre per le ricerche, per poter utilizzare il contain ed effettuare le ricerche velocemente
    itemMessages : [],                //array globale messaggi provenienti dalla ws e che matchano con quelli in attesa
    itemMessagesToast : [],            //i messaggi messi in attesa hanno anche delle informazioni di behaviour o rendering e vengono memorizzati qui 
    delayedTotalRequestMessageToastId: '',  //indice coda messaggi delayed ovvero quelli che arrivano in anticipo sulla ws
    delayedItemMessages : []  //coda messaggi delayed
}
//caso 1:
//dispatch in attesa --> popolo itemMessageToast --> arriva la ws --> se il contenuto match con itemMEssageToast allora riempio itemmessage --> itemmessage va sul toastcomponent
//caso 2:
//dispatch sulla ws in anticipo rispetto alla api della chiamata --> memorizzo in coda delayed --> al primo dispatch compatibile il messaggio passa in itemmessage
export const WsSlice = createSlice({
    name: 'ws',
    initialState,
    reducers: {
        setTotalItemMessageToast: (state,action) => {   //mette un request id in attea 
            //un request id è del tipo 
            /*const itemMessageToast = {
                "requestId" : response.data.requestId,
                "entityName": "Dati Denuncia",
                "taskId": taskId,
                "fetchToEval" : "fetchToEval",
                "localErr" : err  --> sollevato in caso di errore
            } ovviamente la struttura puo' essere estesa ad hoc, il processing di questi messaggi avviene grazie
            al component ToastContent*/
            const oldTotalRequestMessageToastId = state.totalRequestMessageToastId;
            state.totalRequestMessageToastId = '';
            state.totalRequestMessageToastId = oldTotalRequestMessageToastId.concat(action.payload.requestId,";");
            state.itemMessagesToast = [...state.itemMessagesToast, action.payload];
            //Alla fine di questa esecuzione avremo uno stringone utile alle ricerche che contiene gli id e un array di oggetti con le info di rendering
            //Può capitare che venga messo in attesa un toast per il quale già esiste una risposta ws, occorre in tal caso andare a cercarlo nei delayed
            if (action.payload.requestId.toString().trim()!=="" && state.delayedTotalRequestMessageToastId.includes(action.payload.requestId)) {
                const index =state.delayedItemMessages.findIndex((messageToast) => messageToast.requestId === action.payload.requestId);
                const delayedToast =  JSON.stringify(state.delayedItemMessages[index]);
                state.totalItemMessageToast += 1;
                state.itemMessages = [...state.itemMessages, delayedToast];
            }
        },
        removeTotalItemMessageToast: (state,action) => {     //rimuove il message da quelli visualizzati
            if (state.totalRequestMessageToastId.includes(action.payload)) {
                //remove dalle request in attesa
                const oldTotalRequestMessageToastId = state.totalRequestMessageToastId;
                state.totalRequestMessageToastId = '';
                state.totalRequestMessageToastId = oldTotalRequestMessageToastId.replace(action.payload.concat(";"), "");
                
                //remove from itemMessage
                const index = state.itemMessages.findIndex((messageItem) => (JSON.parse(messageItem)).requestId === action.payload);
                if (index!==-1) {
                    state.totalItemMessageToast -= 1;
                    state.itemMessages.splice(index, 1);
                    state.itemMessages = [...state.itemMessages];
                }
                //remove from itemMessageToast
                const index2 = state.itemMessagesToast.findIndex((messageItem) => messageItem.requestId === action.payload);
                if (index2!==-1) {                   
                    state.itemMessagesToast.splice(index2, 1);
                    state.itemMessagesToast = [...state.itemMessagesToast];
                }
            }
        },
        checkTotalItemMessageToast: (state,action) => { //riceve il requestid e verifica che sia tra quelli in attesa in tal caso lo mette tra quelli in visualizzazione
            const readWsData = JSON.parse(action.payload.data);
            //console.log('genericReadWsData', readWsData);
            if (readWsData.requestId.toString().trim()!=="" && state.totalRequestMessageToastId.includes(readWsData.requestId)) {
                //console.log('readWsData', readWsData);
                //console.log('totalReadWsData',state.totalRequestMessageToastId);
                state.totalItemMessageToast += 1;
                state.itemMessages = [...state.itemMessages, action.payload.data];
            }
            else {  //potrebbe arrivarne uno in anticipo, lo mettiamo in attesa
                const oldDelayedTotalRequestMessageToastId = state.delayedTotalRequestMessageToastId;
                state.delayedTotalRequestMessageToastId = '';
                state.delayedTotalRequestMessageToastId = oldDelayedTotalRequestMessageToastId.concat(readWsData.requestId,";");
                state.delayedItemMessages = [...state.delayedItemMessages, readWsData];
            }
        },
    }
})

export const { setTotalItemMessageToast , removeTotalItemMessageToast, checkTotalItemMessageToast } = WsSlice.actions;

export default WsSlice.reducer

