import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  fetchKeys,
  setFilterKeys,
  toogleDataCheck,
} from "../../store/app/DataQualityStatus/DataQualitySlice";
import { DropDownList } from "@progress/kendo-react-dropdowns";

const KeysSelector = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const refreshCount = useSelector(
    (state) => state.dataQualityReducer.refreshCount
  );
  const { versionId } = useParams();


  useEffect(() => {
    dispatch(fetchKeys(versionId));
    // get parameters from url query
    let value;
    try {
      value = JSON.parse(searchParams.get("keys")) || [];
    } catch (error) {
      value = props.defaultValue || [];
      console.error(error);
    }

    dispatch(setFilterKeys(value));
  }, [navigate, refreshCount]);

  const keys = useSelector(
    (state) => state.dataQualityReducer.keys
  );

  const stateValue = useSelector(
    (state) => state.dataQualityReducer.filterKeys
  );

  /**
   * Gestisce il cambio selezione
   * @param {*} event
   */
  const handleChange = (event) => {
    dispatch(setFilterKeys(event.target.value));
    dispatch(toogleDataCheck(true));
  };

  /**
   * Render di un elemento
   * @param {*} li
   * @param {*} itemProps  L'elemento da visualizzare
   * @returns
   */
  const itemRender = (li, itemProps) => {
    const itemChildren = <span>&nbsp;{itemProps?.dataItem?.name}</span>;
    return React.cloneElement(li, li.props, itemChildren);
  };

  return (
    <DropDownList
      data={keys}
      textField="name"
      dataItemKey="id"
      value={stateValue}
      onChange={handleChange}
      itemRender={itemRender}
    />
  );
};

export default KeysSelector;
