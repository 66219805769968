/* eslint-disable prefer-object-spread */
import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../data/axiosInstance/axiosInstance";
import executor from "../../../data/axiosInstance/executor";
import {
  openAlert,
  startLoading,
  stopLoading,
} from "../DataQualityStatus/DataQualitySlice";
import { v4 as uuidv4 } from "uuid";
import { connectToWs } from "../ws/WebSocketSlice";

const API_URL = `${process.env.REACT_APP_API_SINISTRI_URL}`;
const USE_API = true;

const initialState = {
  downloads: [],
  currentDownloadRequest: null,
  lastPaginationValues: {},
  downLoadProgress: 0,
  progressBarShow: false,
};

export const DownloadManagerSlice = createSlice({
  name: "downloadManager",
  initialState,
  reducers: {
    //Values
    getDownloads: (state, action) => {
      state.downloads = action.payload;
    },
    getCurrentDownloadRequest: (state, action) => {
      state.currentDownloadRequest = action.payload;
    },
    updateDownloadProgress: (state, action) => {
      state.downLoadProgress = action.payload;
    },
    toggleProgressBar: (state) => {
      state.downLoadProgress = 0;
      state.progressBarShow = !state.progressBarShow;      
    },
  },
});

export const {
  //Values
  getDownloads,
  getCurrentDownloadRequest,
  updateDownloadProgress,
  toggleProgressBar,
} = DownloadManagerSlice.actions;

export const fetchDownload =
  (requestId = null, page = null, size = null) =>
  async (dispatch) => {
    console.debug("fetchDownload...");
    dispatch(startLoading());
    dispatch(getDownloads([]));

    let res;
    let err;
    if (USE_API) {
      const API_URI_CALL = `${API_URL}/api/v1/portal/files/status`;
      const fetchData = async () =>
        axiosInstance.get(API_URI_CALL, {
          params: {
            requestId: requestId,
            page: page,
            size: size,
          },
          headers: {
            "Content-Type": "application/json",
            accept: "*/*",
          },
        });
      [res, err] = await executor.executeAsync(fetchData);
      if (err) {
        dispatch(
          openAlert({
            message: "Errore durante il recupero dei download: " + err.error,
            type: "danger",
          })
        );
      }
    } else {
      //to Load local json without API
    }

    console.debug("fetchDownload res...", res);
    dispatch(getDownloads(res?.data || []));
    return dispatch(stopLoading());
  };

export const requestDownload = (versionId) => async (dispatch) => {
  console.debug("requestDownload...");
  dispatch(startLoading());
  dispatch(connectToWs());
  dispatch(getCurrentDownloadRequest(null));
  dispatch(
    openAlert({ message: "Richiesta del file in corso...", type: "info" })
  );

  console.log(
    "LocalStorage requestIDsJSON ",
    JSON.stringify(localStorage.getItem("requestIds"))
  );

  const requestId = uuidv4();
  console.log("requestDownload requestId  :  ", requestId);

  // Retrieve existing requestIds from local storage
  let requestIds = JSON.parse(localStorage.getItem("requestIds")) || [];

  // Add the new requestId to the array
  requestIds.push({ requestId: requestId, versionId: versionId });

  // Save the updated array back to local storage
  localStorage.setItem("requestIds", JSON.stringify(requestIds));

  let res;
  let err;

  if (USE_API) {
    let API_URI_CALL = `${API_URL}/api/v1/portal/singleVersion/payloadExport/${versionId}`;
    const fetchData = async () =>
      axiosInstance.post(
        API_URI_CALL,
        {},
        {
          headers: {
            requestId: requestId,
          },
        }
      );
    [res, err] = await executor.executeAsync(fetchData);
    if (err) {
      dispatch(
        openAlert({
          message: "Errore durante il download del file: " + err.error,
          type: "danger",
        })
      );
      return;
    }
  }

  console.debug("requestDownload res...", res);
  dispatch(getCurrentDownloadRequest(res?.data || null));
  return dispatch(stopLoading());
};

export const exportPayloadWithSocket =
  (requestId = null) =>
  async (dispatch) => {
    console.debug("exportPayloadWithSocket start");
    dispatch(startLoading());
    dispatch(toggleProgressBar());

    let res;
    let err;

    if (USE_API) {
      const API_URI_CALL = `${API_URL}/api/v1/portal/files/download/${requestId}`;
      const fetchData = async () =>
        axiosInstance.get(API_URI_CALL, {
          responseType: "blob",
          onDownloadProgress: (progressEvent) => {
            const totalBytes = progressEvent.total;
            const downloadedBytes = progressEvent.loaded;
            const progressPercentage = Math.round(
              (downloadedBytes / totalBytes) * 100
            );
            dispatch(updateDownloadProgress(progressPercentage));
          },
        });
      [res, err] = await executor.executeAsync(fetchData);
      if (err) {
        dispatch(
          openAlert({
            message: "Errore durante la richiesta del download: " + err.error,
            type: "danger",
          })
        );
      }
    } else {
      //to Load local json without API
    }

    const href = URL.createObjectURL(res.data);

    // create "a" HTML element with href to file & click
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute(
      "download",
      res.headers.filename ? res.headers.filename : "gridExport.csv"
    ); //or any other extension
    document.body.appendChild(link);
    dispatch(toggleProgressBar());
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);

    dispatch(stopLoading());
  };

export default DownloadManagerSlice.reducer;
