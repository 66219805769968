import React, { Link, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  useLocation, useNavigate } from "react-router-dom";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { setFilterProcessType, fetchProcessesTypes } from "../../store/app/ProcessRuns/ProcessRunsSlice";
import { toogleDataCheck } from "../../store/app/DataQualityStatus/DataQualitySlice";

const ProcessSelector = () => {
  const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const refreshCount = useSelector(  (state) => state.dataQualityReducer.refreshCount   )

  useEffect(() => {
    dispatch ( fetchProcessesTypes () )
  }, [dispatch])

	useEffect(() => {
		// get parameters from url query
		let value
		try{
			value = JSON.parse(searchParams.get('process')) || []
		}catch (error){
			value = props.defaultValue || []
			console.error (error)
		}

    dispatch (setFilterProcessType (value))
  }, [navigate, refreshCount]);



  const processesTypes = useSelector( (state) => state.processRunsReducer.processesTypes );
  console.log ('processesTypes::fetchProcessesTypes')

  const stateValue = useSelector( (state) => state.processRunsReducer.filterProcessTypes );

  let selectedIds = processesTypes?.reduce ((accumulator, item) => { 
    if (stateValue?.findIndex ((sparam) => sparam == item.id) != -1){
      accumulator.push (item.id)
    }
    return accumulator
  }, [] )


  /**
   * Gestisce il cambio selezione
   * @param {*} event 
   */
  const handleChange = (event) => {

    const currentSelectAll = event.value.some((i) => i.id === null);

    if (currentSelectAll){
      const tutti = event.value.length == processesTypes.length
      if (tutti){
        selectedIds = []
      } else {
        selectedIds = processesTypes.map ( (item) => item.id)
        selectedIds.shift()        
      }
    } else {
      selectedIds = event.value.map ( (item) => item.id)
      // if (selectedIds.length == 0){
      //   setAllSelected (false)
      // }

    }

    // console.log ('processesTypesSelector::handleChange (selectedIds)', selectedIds)
    dispatch (setFilterProcessType (selectedIds))
    dispatch (toogleDataCheck(true))
  };

  /**
   * Render di un elemento
   * @param {*} li 
   * @param {*} itemProps  L'elemento da visualizzare
   * @returns 
   */
  const itemRender = (li, itemProps) => {
    const itemChildren = (
      <span className="form-check py-1">
					<input
            id={`filter_processtype_id_${itemProps.index}`}
						type="checkbox"
						checked={itemProps.selected}
						onChange={(e) => itemProps.onClick(itemProps.index, e)}
						className="form-check-input"
					/>
          <label className="form-check-label">
					&nbsp;{itemProps.dataItem.description}
				</label>
      </span>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };


  const value = processesTypes?.reduce ((accumulator, item) => { 
    if (selectedIds.findIndex ((sparam) => sparam == item.id) != -1){
      accumulator.push (item)
    }
    return accumulator
  }, [] )

  const selectedCount = value.length;

	return (
    <MultiSelect
      data={processesTypes}
      autoClose={false}
      itemRender={itemRender}
      value={value}
      onChange={handleChange}
      dataItemKey="id"
      textField="description"
      tags={
        selectedCount > 0
          ? [{ text: `${selectedCount} processi selezionati`, data: [...value] }]
          : []
        }
      placeholder="Tutti"
    />

	)
};

export default ProcessSelector;

