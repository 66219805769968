/* eslint-disable eqeqeq */
import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    totalNavItem: 0,    //numero di tab aperti
    navTaskList: [],    //elenco di idtask corrispondente ai tab aperti
    taskId: "0",        //task attualmente visualizzato
}

export const AddNavTaskSlice = createSlice({
    name: 'addNavTask',
    initialState,
    reducers: {
        getIdTask: (state, action) => {     //setta il ticket attualmente visualizzato 
            state.taskId = null;
            state.taskId = action.payload.taskId
        },
        addTaskToNav: (state, action) => { 
            if (state.navTaskList.findIndex((taskItem) => taskItem == action.payload.taskId)===-1) {
                state.navTaskList = [ ...state.navTaskList, action.payload.taskId ];
                state.totalNavItem += 1;
             }
            state.taskId = null;
            state.taskId = action.payload.taskId
        },
        removeTaskToNav: (state, action) => {
            const index = state.navTaskList.findIndex((taskItem) => taskItem == action.payload.taskId);
            state.navTaskList.splice(index, 1);
            state.totalNavItem -= 1;
            state.taskId = null;
            state.taskId = (state.totalNavItem > 0) ? state.navTaskList[state.totalNavItem - 1] : '0';
        },
    }
})

//Action creators
export const { getIdTask, addTaskToNav, removeTaskToNav } = AddNavTaskSlice.actions;

export default AddNavTaskSlice.reducer