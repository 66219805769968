import PropTypes from "prop-types";
import moment from "moment";

export const CustomCell = (props, type) => {
  const propsValue = props.dataItem[props.field];

  let formattedValue = "";

  if (propsValue) {
    if (type == "timestamp") {
      formattedValue = moment(propsValue).format("DD/MM/Y - HH:mm:ss");
    }

    if (type == "numeric") {
      formattedValue = propsValue.toLocaleString("fr", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
  }
  return formattedValue == "" ? (
    <td style={{ textAlign: "right", paddingRight: "25px" }}>{propsValue}</td>
  ) : (
    <td style={{ textAlign: "right", paddingRight: "25px" }}>
      {formattedValue}
    </td>
  );
};

CustomCell.propTypes = {
  dataItem: PropTypes.array,
  field: PropTypes.string,
};

export default CustomCell;
