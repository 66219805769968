import PropTypes from "prop-types"
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  setFilterExtendedStatsDomains,
  fetchExtendedStatsDomains,
  toogleDataCheck
} from "../../store/app/DataQualityStatus/DataQualitySlice";
import { MultiSelect } from "@progress/kendo-react-dropdowns";

const ExtendedStatsSelector = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const refreshCount = useSelector(
    (state) => state.dataQualityReducer.refreshCount
  );

  useEffect(() => {
    dispatch(fetchExtendedStatsDomains());
  }, [dispatch]);

  useEffect(() => {
    // get parameters from url query
    let value;
    try {
      value = JSON.parse(searchParams.get("extendedStatsDomains")) || [];
    } catch (error) {
      value = props.defaultValue || [];
      console.error(error);
    }

    dispatch(setFilterExtendedStatsDomains(value));
  }, [navigate, refreshCount]);

  const extendedStatsDomains = useSelector((state) => state.dataQualityReducer.extendedStatsDomains);

  const stateValue = useSelector(
    (state) => state.dataQualityReducer.filterExtendedStatsDomains
  );

  let selectedIds = extendedStatsDomains?.reduce((accumulator, item) => {
    if (stateValue?.findIndex((sparam) => sparam == item.id) != -1) {
      accumulator.push(item.id);
    }
    return accumulator;
  }, []);

  /**
   * Gestisce il cambio selezione
   * @param {*} event
   */
  const handleChange = (event) => {
    const currentSelectAll = event.value.some((i) => i.id === null);

    if (currentSelectAll) {
      const tutti = event.value.length == extendedStatsDomains.length;
      if (tutti) {
        selectedIds = [];
      } else {
        selectedIds = extendedStatsDomains.map((item) => item.id);
        selectedIds.shift();
      }
    } else {
      selectedIds = event.value.map((item) => item.id);
    }

    // console.log ('DomainsSelector::handleChange (selectedIds)', selectedIds)
    dispatch(setFilterExtendedStatsDomains(selectedIds));
    dispatch(toogleDataCheck(true))
  };

  /**
   * Render di un elemento
   * @param {*} li
   * @param {*} itemProps  L'elemento da visualizzare
   * @returns
   */
  const itemRender = (li, itemProps) => {
    const itemChildren = (
      <span className="form-check py-1">
        <input
          id={`filter_extendedStatsDomains_id_${itemProps.index}`}
          type="checkbox"
          checked={itemProps.selected}
          onChange={(e) => itemProps.onClick(itemProps.index, e)}
          className="form-check-input"
        />
        <label className="form-check-label">
          &nbsp;{itemProps.dataItem.name}
        </label>
      </span>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };

  const value = extendedStatsDomains?.reduce((accumulator, item) => {
    if (selectedIds.findIndex((sparam) => sparam == item.id) != -1) {
      accumulator.push(item);
    }
    return accumulator;
  }, []);

  const selectedCount = value.length;

  return (
    <MultiSelect
      data={extendedStatsDomains}
      autoClose={false}
      itemRender={itemRender}
      value={value}
      onChange={handleChange}
      dataItemKey="id"
      textField="name"
      tags={
        selectedCount > 0
          ? [{ text: `${selectedCount} Stati selezionati`, data: [...value] }]
          : []
      }
      placeholder="Tutti"
    />
  );
};

ExtendedStatsSelector.propTypes = {
  defaultValue: PropTypes.array
}

export default ExtendedStatsSelector;
