import { configureStore } from '@reduxjs/toolkit';
import CustomizerReducer from './customizer/CustomizerSlice';
import WsReducer from './app/ws/WsSlice';
import WebSocketReducer from './app/ws/WebSocketSlice';

import DataQualityReducer from './app/DataQualityStatus/DataQualitySlice';
import ProcessRunsReducer from './app/ProcessRuns/ProcessRunsSlice';
import DataRollCallReducer from './app/DataRollCall/DataRollCallSlice';
import DownloadManagerReducer from './app/DownloadManager/DownloadManagerSlice';
import PkceReducer from './app/pkce/PkceSlice';


export const store = configureStore({
  reducer: {

    dataQualityReducer: DataQualityReducer,
    processRunsReducer: ProcessRunsReducer,
    dataRollCallReducer: DataRollCallReducer,
    downloadManagerReducer: DownloadManagerReducer,


    customizer: CustomizerReducer,
    wsReducer : WsReducer,
    pkceReducer: PkceReducer,
    webSocketReducer: WebSocketReducer,
  }
});

export default store;
