import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Container, Row, Col, Label, Form, Button } from "reactstrap";

import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { ColumnProps } from "../components/kendoComponents/columnProps/ColumnProps";
import { openAlert } from "../store/app/DataQualityStatus/DataQualitySlice";
import "../assets/scss/kendo-theme-custom.scss";


import "react-hammerjs";

import LoaderSpin from "../components/loaderSpin/LoaderSpin";
import ProgressBar from "../components/progressBar/ProgressBar";

import { useNavigate } from "react-router-dom";

import {
  fetchDownload,
  exportPayloadWithSocket,
} from "../store/app/DownloadManager/DownloadManagerSlice";

import { DateCell } from "../components/kendoComponents/dateCell/DateCell";


const DownloadManager = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isSpinnerVisible = useSelector(
    (state) => state.dataQualityReducer.loading
  );

  const isProgressBarVisible = useSelector(
    (state) => state.downloadManagerReducer.progressBarShow
  );

  const downloadProgress = useSelector(
    (state) => state.downloadManagerReducer.downLoadProgress
  );

  const downloads = useSelector(
    (state) => state.downloadManagerReducer.downloads.content || []
  );
  const totalElements = useSelector(
    (state) => state.downloadManagerReducer.downloads.totalElements || 0
  );

  const [dataState, setDataState] = useState({ skip: 0, take: 15 });
  const [page, setPage] = useState(0);
  const [pageSizeValue, setPageSizeValue] = useState(15);


  useEffect(() => {
    dispatch(fetchDownload(null, page, pageSizeValue));
  }, [navigate, dataState.skip, dataState.take]);


  return (
    <>
      <Container fluid className="py-2 px-4  position-relative ">
        <Row>
          <Col>
            <div className="grid_task_container_tabs border overflow-x-scroll position-relative">
              <div className="position-absolute w-100 h-100 top-0 start-0">
                <Grid
                  style={{ height: "100%" }}
                  scrollable="scrollable"
                  fixedScroll={true}
                  pageable={{
                    buttonCount: 4,
                    pageSizes: [15, 20, 30],
                    pageSizeValue: pageSizeValue,
                  }}                  
                  total={totalElements}
                  refresh={true}
                  data={downloads}
                  {...dataState}
                  onPageChange={(e) => {
                    setDataState({skip: e.page.skip, take: e.page.take});
                    setPage(e.page.skip / e.page.take );
                    setPageSizeValue(e.page.take);
                  }}
                >
                  <Column
                    {...ColumnProps("url", dataState)}
                    title="Nome File"
                    //columnMenu={ColumnMenu}
                    cell={(props) => {
                      const url = props.dataItem.url;
                      const fileName = url.substring(url.lastIndexOf("/") + 1);

                      return (
                        <td>
                          <a
                            style={{ cursor: "pointer" }}
                            className="fw-bold text-decoration-underline"
                            onClick={(event) => {
                              if(props.dataItem.stato === "COMPLETED") {
                              dispatch(
                                exportPayloadWithSocket(
                                  props.dataItem.requestId
                                )
                              );
                            } else {
                              dispatch(openAlert({
                                title: "Attenzione!",
                                message: "Il download non è ancora disponibile, riprova più tardi",
                                type: "info"
                              }));
                            }
                          }}
                            onKeyDown={() => console.log("onKeyDown")}
                          >
                            {fileName}
                          </a>
                        </td>
                      );
                    }}
                  />
                  <Column
                    {...ColumnProps("dataRichiesta", dataState)}
                    title="Data Richiesta"
                    //columnMenu={ColumnMenu}
                    cell={(props) => DateCell(props, true)}
                  />
                  <Column
                    {...ColumnProps("dataCreazione", dataState)}
                    title="Data Creazione"
                    //columnMenu={ColumnMenu}
                    cell={(props) => DateCell(props, true)}
                  />
                  <Column
                    {...ColumnProps("stato", dataState)}
                    title="Stato"
                    //columnMenu={ColumnMenu}
                  />
                </Grid>
              </div>
            </div>
          </Col>
        </Row>

        <LoaderSpin  data-testid="loader-spin" isVisible={isSpinnerVisible} />
        <ProgressBar data-testid="progress-bar" isVisible={isProgressBarVisible} value={downloadProgress} />

        {/* <div className="filter_results_mask d-flex align-content-center flex-wrap justify-content-center">
          <div className=" p-4 text-light text-center">
            <p className="fs-3">
              Applica i filtri selezionati per aggiornare.</p>
            <p className="fs-3 mt-4">
              Oppure, resetta i filtri per riportarli allo stato coerente con i risultati mostrati.</p>
          </div>
        </div> 
        Questa sarebbe la maschera da sovrapporre ai risultati, credo che dovremmo trasformarla in un componente a cui passare un parametro acceso/spento in base alla coerenza tra filtri e risultati
        */}
      </Container>
    </>
  );
};

export default DownloadManager;
