/* eslint-disable prefer-object-spread */
import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../../data/axiosInstance/axiosInstance'; 
import executor from   '../../../data/axiosInstance/executor';

const API_URL = `${process.env.REACT_APP_API_SINISTRI_URL}`;
 
const initialState = {
  allegati: []
};

export const AllegatiSlice = createSlice({
  name: 'allegati',
  initialState,
  reducers: {
    getAllegati: (state, action) => {
      //state.allegati = [];
      state.allegati = action.payload;
    }
  }
});

export const { getAllegati } = AllegatiSlice.actions;

export const fetchAllegati = (idSinistro) => async (dispatch) => {   
  dispatch(getAllegati([]));

  const API_URI_CALL = `${API_URL}/api/v1/bff/denunce/lista/allegati/${idSinistro}?size=50&page=0`;
  const fetchData = async  () => axiosInstance.get(API_URI_CALL);
  const [res, err] = await executor.executeAsync(fetchData);

  console.log('res',res); 
  console.log('err',err);
   
  return dispatch(getAllegati(res.data.content));   
}; 

export default AllegatiSlice.reducer;
