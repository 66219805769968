import axios from "axios";
//import { Exception } from "sass";
import AuthService from "../../components/jwt/auth.service";
import {
  setTotalItemMessageToast,
  checkTotalItemMessageToast,
} from "../../store/app/ws/WsSlice";

const axiosInstance = axios.create();

const user = JSON.parse(localStorage.getItem("userInfo"));
if (user) {
  axiosInstance.defaults.headers.common = {
    Authorization: `Bearer ${user.access_token}`,
  };
}

axiosInstance.interceptors.request.use((request) => {
  const uuID = crypto.randomUUID();
  const userToken = JSON.parse(localStorage.getItem("userInfo"));

  if (userToken) {
    // axiosInstance.defaults.headers.common = { Authorization: `Bearer ${userToken.access_token}`, requestId: uuID };
    axiosInstance.defaults.headers.common = {
      Authorization: `Bearer ${userToken.access_token}`,
    };
  }

  return request;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const uuID = crypto.randomUUID();

    if (error.response.status === 401) {
      try {
        //retrieve the new token
        await AuthService.refreshToken();
        const userToken = JSON.parse(localStorage.getItem("userInfo"));

        if (userToken) {
          originalRequest.headers.Authorization = `Bearer ${userToken.access_token}`;
          originalRequest.headers.requestId = uuID;
        }
        return axiosInstance(originalRequest);
      } catch (error) {
        return window.open("/auth/login", "_self");
      }
    }
    return Promise.reject(error);
  }
);

const setToken = () => {
  const userToken = JSON.parse(localStorage.getItem("userInfo"));
  const uuID = crypto.randomUUID();
  if (userToken) {
    axiosInstance.defaults.headers.common = {
      Authorization: `Bearer ${userToken.access_token}`,
      requestId: uuID,
    };
  }
};

axiosInstance.Cattura = (e, taskId, name, fetch, dispatch) => {
  console.log("Err on PutDenuncia ", e);
  const uuID = crypto.randomUUID(); //simuliamo una requestId
  const itemMessageToastError = {
    requestId: uuID,
    entityName: name,
    taskId: taskId,
    fetchToEval: fetch,
    localErr: e,
  };
  //localErr contiene l'eccez sollevata dal backend API (non worker)
  dispatch(setTotalItemMessageToast(itemMessageToastError));
  const itemError = `{"requestId":"${uuID}", "operation":{"status":"FAILED", "statusCode": "7500"}}`;
  const itemErrorObj = { data: itemError };
  //simuliamo il dispatch della ws
  dispatch(checkTotalItemMessageToast(itemErrorObj));
};

export const axiosInstanceService = {
  setToken,
};

export default axiosInstance;
