import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Input } from "reactstrap";
import { setFilterMese, setFilterDataDa, setFilterDataA, toogleDataCheck, setFilterIntervallo} from "../../store/app/DataQualityStatus/DataQualitySlice";
import moment from "moment";



const FilterMese = (props) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const filterMese = useSelector((state) => state.dataQualityReducer.filterMese)



	const makeMonthsInYear = () => {
		let index = 0;
		let months = [];
		while (index < 12) {
			months.push(
				{
					id: index,
					name: moment().subtract(index, 'month').format('MMMM YYYY').toString()
				}
			)
			index+=1
		}	
		return months;
	}



	useEffect(() => {
		dispatch(setFilterMese(filterMese))
		dispatch(setFilterIntervallo(-1))
		dispatch(setFilterDataA(moment().startOf('month').subtract(filterMese, 'months').format('YYYY-MM-DD hh:mm:ss')))
		dispatch(setFilterDataDa(moment().endOf('month').subtract(filterMese, 'months').format('YYYY-MM-DD hh:mm:ss')))		
	}, []);


	const meseChanged = (e) => {
		const value = e.target?.value || e;
		dispatch(setFilterMese(value))
		dispatch (toogleDataCheck(true))
		dispatch(setFilterDataA(moment().startOf('month').subtract(value, 'months').format('YYYY-MM-DD hh:mm:ss')))
		dispatch(setFilterDataDa(moment().endOf('month').subtract(value, 'months').format('YYYY-MM-DD hh:mm:ss')))		
	};



	return (
		<Input
			type="select"
			name="mese"
			{...props}
			onChange={meseChanged}
			value={filterMese}
		>
			{makeMonthsInYear().map( month => (<option key={month.id} value={month.id}>{month.id == 0 ?  month.name + " (Parziale) " :month.name}</option> ))}
			
			
			</Input>
	)

}

export default FilterMese;