import FeatherIcon from 'feather-icons-react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';  
import { fetchTicket } from '../../store/app/ticket/TicketSlice';
import { addTaskToNav } from '../../store/app/addNavTask/AddNavTaskSlice';

const SectionHeading = ({ iconClass, idTicketPadre, headingTitle, subHeadingTitle, nSinistro }) => {
  const dispatch = useDispatch();
  const handleAddTaskTab = () => {
    dispatch(fetchTicket({ taskId: idTicketPadre }));
    dispatch(addTaskToNav({ taskId: idTicketPadre }));
  };
  return (

    <div className="section_heading d-flex">
      <div className="section_icon">
        <FeatherIcon icon={iconClass} size={18} />
      </div>
      <div>
         {subHeadingTitle ? 
            <>
          <h2 className="section_title pt-0 lh-sm">
          {`${headingTitle}`}
          </h2>
          <p className="mb-0 lh-sm">{`${subHeadingTitle}`}</p>
          </>
          :
            <h2 className="section_title">{`${headingTitle}`}</h2>
          }
      </div>
    </div>
  );
};

export default SectionHeading;
