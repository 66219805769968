import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from './useAuth';
import LoginFormSSO from "../forms/form_login/FormLoginSSO";
import Err from '../../views/auth/Error';

const AuthGuard = (props) => {
  const { children } = props;
  const auth = useAuth();
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);
  const lastKnownPath =location.pathname;

  
  if (!auth) {
    localStorage.setItem("lastKnownPath", lastKnownPath);
    return <LoginFormSSO />;
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
