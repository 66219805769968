import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { axiosInstanceService } from "../../../data/axiosInstance/axiosInstance";
import AuthServiceSSO from "../../jwt/auth.serviceSSO";
import { useSelector, useDispatch } from "react-redux";
import { generateCodeVerifier } from "../../../store/app/pkce/PkceSlice";
import { useEffectOnChange } from "../../hooks/CustomHooks";
import { connectToWs } from "../../../store/app/ws/WebSocketSlice";

const FormLoginSSO = () => {
  const params = new URLSearchParams(window.location.search);

  const APISICUREZZA = process.env.REACT_APP_SICUREZZA_URL;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [code, setCode] = useState("");

  const codeVerifier = useSelector((state) => state.pkceReducer.codeVerifier);
  const codeChallenge = useSelector((state) => state.pkceReducer.codeChallenge);

  useEffect(() => {
    if (params.get("code")) {
      setCode(params.get("code"));
    } else {
      dispatch(generateCodeVerifier());
    }
  }, []);

  useEffectOnChange(() => {
    window.location.replace(
      `${APISICUREZZA}/auth/realms/revo/protocol/openid-connect/auth?client_id=revo-access&response_type=code&scope=openid&redirect_uri=` +
        window.location.href +
        "&code_challenge=" +
        codeChallenge +
        "&code_challenge_method=S256"
    );
  }, [codeChallenge]);

  useEffectOnChange(() => {
    AuthServiceSSO.login(params.get("code"), codeVerifier).then((responsed) => {
      localStorage.setItem("userInfo", JSON.stringify(responsed));
      axiosInstanceService.setToken();
      if (responsed.access_token) {
        dispatch(connectToWs());
        localStorage.getItem("lastKnownPath") ? navigate(localStorage.getItem("lastKnownPath")) : navigate("/home");
      } else {
        localStorage.setItem("message", "400");
      }
    });
  }, [code]);
};

export default FormLoginSSO;
