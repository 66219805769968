import { useEffect, useState } from 'react';

import "./auth/Error.scss";
import errorBg from '../assets/images/background/error-bg2.jpg';


//TODO: here we have to retrieve the stacktrace
const ErrorGeneric = (props) => {
  const [formData, setFormData] = useState(props);
  localStorage.removeItem('userInfo');

  useEffect(() => {    
     if (formData) {
       setFormData(props);       
     }
   }, [props]);

  return (
    <>
      <div
        className="loginBox"
        style={{ background: `url(${errorBg}) no-repeat bottom center #fff` }}
      >
        <div className="d-flex align-items-center justify-content-center h-100">
          <div className="text-center">
            <h1 className='error-title'>Ops</h1>
            <div className="my-3">
              <h4>Errore generico TODO: stack trace descr e redux datablock</h4>
              <span className="text-muted d-block fs-5">
               {formData.message}
              </span>
            </div>
            <a href="/home" className="btn btn-primary">
              Rieffettuare il login
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorGeneric;

//https://www.npmjs.com/package/react-error-boundary
//https://dev.to/edemagbenyo/handle-errors-in-react-components-like-a-pro-l7l#:~:text=React%20Error%20Boundary,-Error%20boundaries%20are&text=In%20order%20to%20use%20Error,use%20react%2Derror%2Dboundary.&text=When%20we%20run%20this%20application,below%20them%20in%20the%20tree.
//https://kentcdodds.com/blog/use-react-error-boundary-to-handle-errors-in-react
