import {Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Button } from 'reactstrap';

const ModalManualValidation = ({modal, status, toggleManualValidation, versionId, handleOk }) => {
  return (
      <Modal isOpen={modal} size="xs mt-6">
        <ModalHeader className="" toggle={toggleManualValidation}>
          <h5 className="pt-2 fw-bold">
          Nuova validazione manuale
          </h5>
        </ModalHeader>
        <ModalBody>
          <Form>
            <div className="form-body">
              <Row>
                <Col>
                  <FormGroup>
                    <Label for="testo">Sei sicuro di voler procedere {status ? "validando" : "invalidando"} la versione {versionId}?</Label>
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </Form>
        </ModalBody>
        <ModalFooter className="border-primary justify-content-between">
          <Button color="primary" outline className="text-uppercase" onClick={toggleManualValidation}>
            Annulla
          </Button>
          <Button color="primary" className="text-uppercase" onClick = { handleOk }>
							Conferma
					</Button>
        </ModalFooter>
      </Modal>    
  )
}

export default ModalManualValidation