/* eslint-disable prefer-object-spread */
import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../../data/axiosInstance/axiosInstance'; 
import executor from   '../../../data/axiosInstance/executor';

const API_URL = `${process.env.REACT_APP_API_SINISTRI_URL}`;
 
const initialState = {
  note: []
};

export const NoteSlice = createSlice({
  name: 'note',
  initialState,
  reducers: {
    getNote: (state, action) => {
      state.note = action.payload;
    },
    setAddNota: (state, action) => {
      state.note = [action.payload, ...state.note];
    },
    updateNota: (state, action) => {
      //console.log('state action ', action.payload);
      const {descrizione, nota, id, data} = action.payload;
      const foundNota = state.note.find(notaLista => notaLista.id === id);
      if(foundNota) {
        foundNota.descrizione = descrizione
        foundNota.nota = nota
        foundNota.dataCreazione = data
      }
    },
    deleteNota: (state, action) => {
      const foundNota = state.note.find(nota => nota.id === action.payload);
      if(foundNota) {
        state.note.splice(state.note.indexOf(foundNota), 1)
      }
    }
  }
});

export const { getNote, setAddNota, updateNota, deleteNota} = NoteSlice.actions;

export const fetchNote = (taskId) => async (dispatch) => {   
  dispatch(getNote([]));

  if(taskId) {
      const API_URI_CALL_ESITI = `${API_URL}/api/v1/bff/task/diario/${taskId}`;
      const fetchDataEsiti = async () => axiosInstance.get(API_URI_CALL_ESITI);
      const [resDiarioTrattazione, errDiarioTrattazione] = await executor.executeAsync(fetchDataEsiti); 
      console.log('errDiarioTrattazione ', errDiarioTrattazione);
      console.log('resDiarioTrattazione ', resDiarioTrattazione.data);
      return dispatch(getNote(resDiarioTrattazione.data));
    }
    return ""
}; 

export default NoteSlice.reducer;
