import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchTicket,fetchListaDanneggiati,fetchListaGaranzieDanneggiato, fetchRiserveComplessive, setListaSoggettiCausa, fetchListaIncaricatoNoQuadro, fetchRiserveSpeseIncarico, addIncaricato, setMotivazioneIncarico, setMotivazioneIncaricoNoQuadro, fetchCauseIstruttoria, fetchIncarichiIstruttoria, getListaIncaricatoNoQuadro, fetchListaBeneficiariIndennizzo } from '../../store/app/ticket/TicketSlice';
import { addTaskToNav } from '../../store/app/addNavTask/AddNavTaskSlice';
import { removeTotalItemMessageToast } from '../../store/app/ws/WsSlice';
import { fetchAllegati  } from '../../store/app/allegati/AllegatiSlice';
import { fetchEmails } from '../../store/apps/email/EmailSlice';
import { fetchNote} from '../../store/app/diarioTrattazione/NoteSlice';

// Il component renderizza le informazioni nel toast
// e in funzione delle info ricevute dalla ws scatena 
// le operazioni di fetch
const ToastContent = (props) => {
    const dispatch = useDispatch();
    const requestIdFromProps = props.message;

    const totalRequestMessageToast = useSelector((state) => state.wsReducer.itemMessagesToast);
    const soggetti = useSelector((state) => state.ticketReducer.openedTicket.datiCausa?.listaSoggetti?.content)
    const index = totalRequestMessageToast.findIndex((messageToast) => messageToast.requestId === JSON.parse(requestIdFromProps).requestId);
    const localToast = totalRequestMessageToast[index];
   
    
    const openedTicketLocal = (useSelector((state) => state.ticketReducer.openedTicket));
    const idSinistro = openedTicketLocal.datiDenuncia?.idSinistro;
    // todo controllare local err nei file
    const handleAddTaskTab = () => {  
        dispatch(fetchTicket( {taskId: localToast.taskId }));
        dispatch(addTaskToNav({ taskId: localToast.taskId }));          
    };

    // const handleAddTaskTabFiglioToPadre = (id) => {
    //     dispatch(fetchTicket( {taskId: id }));
    // }


    const handleResult = () => {
        //here we process fecth if we need it
        if (localToast?.fetchToEval === "Allegati") dispatch(fetchAllegati(idSinistro));
        
        if (localToast?.fetchToEval === "Appuntamento")   dispatch(fetchNote(localToast.taskId));

        if (localToast?.fetchToEval === "Danneggiato"){
            dispatch(fetchListaDanneggiati(idSinistro));
            dispatch(fetchListaGaranzieDanneggiato(localToast.taskId, localToast.danneggiatoId, localToast.ramoParams, localToast.idTitolo, localToast.idBene));
            dispatch(fetchRiserveComplessive(idSinistro));
        }
        
        if (localToast?.fetchToEval === "Email") dispatch(fetchEmails(openedTicketLocal?.datiTask?.idTask));

        if (localToast?.fetchToEval === "Diario") dispatch(fetchNote(localToast.taskId));

        if (localToast?.fetchToEval === "Soggetto") dispatch(setListaSoggettiCausa(soggetti === null)) ;

        if (localToast?.fetchToEval === "Incaricato"){
            dispatch(fetchListaIncaricatoNoQuadro(localToast.incaricatoId));
            dispatch(addIncaricato(localToast.objIncaricato));
            dispatch(setMotivazioneIncarico(null));
            dispatch(setMotivazioneIncaricoNoQuadro(localToast?.motivazione));
            localToast.setDataFiduciarioNoQuadro([localToast.objIncaricatoLista]) 
        }
        
        if (localToast?.fetchToEval === "Incaricato_Modificato") {
            localToast.setDataFiduciarioNoQuadro([localToast.objIncaricato]);
            dispatch(fetchListaIncaricatoNoQuadro(localToast.incaricatoId));
            dispatch(setMotivazioneIncaricoNoQuadro(localToast?.motivazione));
        }

        if (localToast?.fetchToEval === "Incaricato_Eliminato") {
            localToast.setDataFiduciarioNoQuadro([])
            dispatch(getListaIncaricatoNoQuadro([]))
        };
            
        if (localToast?.fetchToEval === "RiservaSpese") dispatch(fetchRiserveSpeseIncarico(localToast.riservaSpesaId));
        
        if (localToast?.fetchToEval === "Causa"){
            dispatch(fetchCauseIstruttoria(localToast.idSinistro));
        }
        
        if (localToast?.fetchToEval === "Incarico"){
            dispatch(fetchIncarichiIstruttoria(localToast.idSinistro));
        }

        if(localToast?.fetchToEval === "Fiduciario") dispatch(fetchIncarichiIstruttoria(localToast.idSinistro));

        if(localToast?.fetchToEval === "Beneficiario") dispatch(fetchListaBeneficiariIndennizzo(localToast.pagamentoId));

        if(localToast?.fetchToEval === "PagamentoSpesa") dispatch(fetchIncarichiIstruttoria(localToast.idSinistroPagSpese));
        
        //last but not least
        if (localToast?.loader) localToast.loader();
    }

    useEffect(() => {
        let  timeout = 6000;
        if (localToast?.navigate) timeout = 500;
        handleResult();
        setTimeout(() => {
            dispatch(removeTotalItemMessageToast(localToast?.requestId));            
            //if (localToast?.fetchToEval === "Esitazione" && localToast.idTaskPadre !== undefined && localToast.idTaskPadre !== "") handleAddTaskTabFiglioToPadre(localToast?.idTaskPadre)
            let idLink = localToast?.taskId;
            if (localToast.idTaskPadre !== undefined && localToast.idTaskPadre !== "") 
                idLink = localToast?.idTaskPadre;
            if (localToast?.navigate) document.getElementById(idLink).click();
        }, timeout);
    }, [props]);

    return (   
        <>
            <h5 className="fw-bold">
                Aggiornamento <span className="text-lowercase">{localToast?.entityName}</span>
            </h5>
            <p>
            {`${JSON.parse(requestIdFromProps).operation.status}  `}
            { (localToast?.localErr?.message) ? `${localToast?.localErr?.message}` : null}
            </p>
            {
                (localToast.idTaskPadre !== undefined && localToast.idTaskPadre !== "") ?
                <Link id={localToast?.idTaskPadre}  to={`/task-${localToast?.idTaskPadre}`}  onClick={handleAddTaskTab} className="btn btn-primary btn-sm">
                 Task:_{localToast?.idTaskPadre}
                </Link> :
                 <Link id={localToast?.taskId}  to={`/task-${localToast?.taskId}`}  onClick={handleAddTaskTab} className="btn btn-primary btn-sm">
                 Task:_{localToast?.taskId}
             </Link> 


            }  
            
            {/* <Link id={localToast?.taskId}  to={`/task-${localToast?.taskId}`}  onClick={handleAddTaskTab} className="btn btn-primary btn-sm">
                Task:_{localToast?.taskId}
            </Link>  */}

            <br/>
        </> 
    )
  }
  
  export default ToastContent;
  