import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import {
  setFilterDQIssueTypes,
  fetchDQIssueTypes,
} from "../../store/app/ProcessRuns/ProcessRunsSlice";
import { toogleDataCheck } from "../../store/app/DataQualityStatus/DataQualitySlice";

const DQIssueSelector = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const refreshCount = useSelector(
    (state) => state.dataQualityReducer.refreshCount
  );

  useEffect(() => {
    dispatch(fetchDQIssueTypes());
  }, [dispatch]);

  // useEffect(() => {
  //   // get parameters from url query
  //   let value;
  //   try {
  //     value =  {id: JSON.parse(searchParams.get("issuetypes")) } || {};
    
  //   } catch (error) {
  //     value = props.defaultValue || [];
  //     console.error(error);
  //   }

  //   dispatch(setFilterDQIssueTypes(value));
  // }, [navigate, refreshCount]);

  const dqIssueTypes = useSelector(
    (state) => state.processRunsReducer.dqIssueTypes
  );

  const stateValue = useSelector(
    (state) => state.processRunsReducer.filterDQIssueTypes
  );

  /**
   * Gestisce il cambio selezione
   * @param {*} event
   */
  const handleChange = (event) => {
    dispatch(setFilterDQIssueTypes(event.target.value));
    dispatch(toogleDataCheck(true));
  };

  /**
   * Render di un elemento
   * @param {*} li
   * @param {*} itemProps  L'elemento da visualizzare
   * @returns
   */
  const itemRender = (li, itemProps) => {
    const itemChildren = <span>&nbsp;{itemProps?.dataItem?.description}</span>;
    return React.cloneElement(li, li.props, itemChildren);
  };

  return (
    <DropDownList
      data={dqIssueTypes}
      textField="description"
      dataItemKey="id"
      value={stateValue}
      onChange={handleChange}
      itemRender={itemRender}
    />
  );
};

export default DQIssueSelector;
