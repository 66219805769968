import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { fetchProductTypes, setFilterProductTypes } from "../../store/app/DataRollCall/DataRollCallSlice";
import { toogleDataCheck } from "../../store/app/DataQualityStatus/DataQualitySlice";


const ProductTypeSelector = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const refreshCount = useSelector((state) => state.dataQualityReducer.refreshCount)



    useEffect(() => {
        dispatch(fetchProductTypes());
      }, [dispatch]);
    

    useEffect(() => {
        // get parameters from url query
        let value
        try {
            value = JSON.parse(searchParams.get('producttypes')) || [];
        } catch (error) {
            value = props.defaultValue || []
            console.error(error)
        }

        dispatch(setFilterProductTypes(value))
    }, [navigate, refreshCount]);




    const productTypes = useSelector((state) => state.dataRollCallReducer.productTypes);

    const stateValue = useSelector((state) => state.dataRollCallReducer.filterProductTypes);

    let selectedIds = productTypes?.reduce((accumulator, item) => {
        if (stateValue?.findIndex((sparam) => sparam == item.productId) != -1) {
            accumulator.push(item.productId)
        }
        return accumulator
    }, [])


    /**
     * Gestisce il cambio selezione
     * @param {*} event 
     */
    const handleChange = (event) => {

        const currentSelectAll = event.value.some((i) => i.productId === null);

        if (currentSelectAll) {
            const tutti = event.value.length == productTypes.length
            if (tutti) {
                selectedIds = []
            } else {
                selectedIds = productTypes.map((item) => item.productId)
                selectedIds.shift()
            }
        } else {
            selectedIds = event.value.map((item) => item.productId)
        }

        dispatch(setFilterProductTypes(selectedIds))
        dispatch(toogleDataCheck(true))
    };

    /**
     * Render di un elemento
     * @param {*} li 
     * @param {*} itemProps  L'elemento da visualizzare
     * @returns 
     */
    const itemRender = (li, itemProps) => {
        const itemChildren = (
            <span className="form-check py-1">
                <input
                    id={`filter_producttypes_id_${itemProps.index}`}
                    type="checkbox"
                    checked={itemProps.selected}
                    onChange={(e) => itemProps.onClick(itemProps.index, e)}
                    className="form-check-input"
                />
                <label className="form-check-label">
                    &nbsp;{itemProps.dataItem.productName}
                </label>
            </span>
        );
        return React.cloneElement(li, li.props, itemChildren);
    };


    const value = productTypes?.reduce((accumulator, item) => {
        if (selectedIds.findIndex((sparam) => sparam == item.productId) != -1) {
            accumulator.push(item)
        }
        return accumulator
    }, [])

    const selectedCount = value.length;

    return (
        <MultiSelect
            data={productTypes}
            autoClose={false}
            itemRender={itemRender}
            value={value}
            onChange={handleChange}
            dataItemKey="productId"
            textField="productName"
            tags={
                selectedCount > 0
                    ? [{ text: `${selectedCount} prodotti selezionati`, data: [...value] }]
                    : []
            }
            placeholder="Tutti"
        />
    )
};

export default ProductTypeSelector;