/* eslint-disable prefer-object-spread */
import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../../data/axiosInstance/axiosInstance';
import executor from '../../../data/axiosInstance/executor';
import jwtDdecode from "jwt-decode";
import { openAlert } from '../DataQualityStatus/DataQualitySlice';

const API_URL = `${process.env.REACT_APP_API_SINISTRI_URL}`;
const USE_API = true;


const initialState = {
  loading: false,
  refreshCount: 0,
  callWithUser: false,
  statusCount: [],
  activeFilter: {},

  productTypeList: [],
  versionList: [],
  activitiesStatusCount: [],
  activitiesCount: 0,

  productTypes: [],
  filterProductTypes: [],

  rollCallStatusDomains: [],
  filterStatusCountDomains: []

};


export const DataRollCallSliceSlice = createSlice({
  name: 'dataRollCall',
  initialState,
  reducers: {
    //General
    setRefreshCount: (state, action) => {
      state.refreshCount = action.payload
    },
    toogleLoading: (state) => {
      state.loading = !state.loading;
    },
    startLoading: (state) => {
      state.loading = true
    },
    stopLoading: (state) => {
      state.loading = false
    },
    setCallWithUser: (state, action) =>{
      state.callWithUser = action.payload
    },
    //Values
    getStatusCount: (state, action) => {
      state.statusCount = action.payload;
    },
    getProductTypeList: (state, action) => {
      state.productTypeList = action.payload
    },
    getRollCallStatusDomains: (state, action) => {
      state.rollCallStatusDomains = action.payload
    },
    getVersionList: (state, action) => {
      state.versionList = action.payload;
    },
    getProductTypes: (state, action) => {
      state.productTypes = action.payload
    },
    getActivitiesStatusCount: (state, action) => {
      state.activitiesStatusCount = action.payload
    },
    getActivitiesCount: (state, action) => {
      state.activitiesCount = action.payload
    },

    //Filters
    setActiveFilter: (state, action) => {
      state.activeFilter = action.payload;
    },
    setFilterProductTypes: (state, action) => {
      state.filterProductTypes = action.payload
    },
    setFilterStatusCount: (state, action) => {
      state.filterStatusCountDomains = action.payload
    },
  }
});


export const {
  //General
  setRefreshCount,
  toogleLoading,
  startLoading,
  stopLoading,
  setCallWithUser,

  //Values
  getStatusCount,
  getProductTypeList,
  getRollCallStatusDomains,
  getProductTypes,
  getVersionList,
  getActivitiesStatusCount,
  getActivitiesCount,



  //Filters
  setActiveFilter,
  setFilterProductTypes,
  setFilterStatusCount,

} = DataRollCallSliceSlice.actions;


export const fetchStatusCount = (domains) => async (dispatch) => {
  console.debug('fetchStatusCount ', domains);
  dispatch(startLoading());
  dispatch(getStatusCount([]));
  let res
  let err
  
  if (USE_API) {
    const API_URI_CALL = `${API_URL}/api/v1/portal/dataRoll/statusCount`;
    const fetchData = async () => axiosInstance.post(API_URI_CALL, domains || undefined);
    [res, err] = await executor.executeAsync(fetchData);
    if (err) {
      dispatch(openAlert({ message: "Errore durante il recupero degli status count: " + err.error, type: "danger" }));
    }
  } else {
    res = {
      "data":
        [
          {
            "roll_call_status_id": "1",
            "roll_call_status_name": "Waiting",
            "count": 4,
            "info": {
              "color": "#ffff00"
            }
          },
          {
            "roll_call_status_id": "2",
            "roll_call_status_name": "Row 2",
            "count": 14,
            "info": {
              "color": "red"
            }
          },
          {
            "roll_call_status_id": "3",
            "roll_call_status_name": "Row 3",
            "count": 214,
            "info": {
              "color": "black"
            }
          }
        ]
    };
  }

  console.debug('fetchStatusCount res', res);
  dispatch(getStatusCount(res?.data || []));
  return dispatch(stopLoading());
};
export const fetchProductTypeList = (rollCallStatusDomains, domains, callWithUser) => async (dispatch) => {
  console.debug('fetchProductTypeList...')
  dispatch(startLoading());
  dispatch(getProductTypeList([]));
  const currentuser = JSON.parse(localStorage.getItem('userInfo'));
  let res
  let err

  let decoded = {};

  if (currentuser) {
    decoded = jwtDdecode(currentuser.access_token);
  } else {
    decoded.name = '';
    decoded.email = '';
  }
  
  if (USE_API) {
    const API_URI_CALL = (callWithUser) ?  `${API_URL}/api/v1/portal/dataRoll/productTypeList?userName=` + decoded.email : `${API_URL}/api/v1/portal/dataRoll/productTypeList`;
    const fetchData = async () => axiosInstance.post(API_URI_CALL, {
      statusList: rollCallStatusDomains || [],
      domainList: domains || [],
    });
    [res, err] = await executor.executeAsync(fetchData)
    if (err) {
      dispatch(openAlert({ message: "Errore durante il recupero della lista dei prodotti: " + err.error, type: "danger" }));
    }

  } else {
    //to Load local json without API
  }

  console.debug('fetchProductTypeList res', res);
  dispatch(getProductTypeList(res?.data || []));
  return dispatch(stopLoading());

};
export const fetchRollCallStatusDomains = (isValid) => async (dispatch) => {
  console.debug('DataRollCall::fetchRollCallStatusDomains', isValid);
  dispatch(startLoading());
  dispatch(getRollCallStatusDomains([]));
  let res = null
  let err = null
  if (USE_API) {

    const API_URI_CALL = `${API_URL}/api/v1/portal/dataRoll/getRollCallStatusDomains/` + isValid?.toString();
    const fetchData = async () => axiosInstance.get(API_URI_CALL, {});
    [res, err] = await executor.executeAsync(fetchData);
    if (err) {
      dispatch(openAlert({ message: "Errore durante il recupero degli status domains: " + err.error, type: "danger" }));
    }
    res?.data?.unshift({ rollCallStatusDesc: "Tutti", rollCallStatusName: "Tutti", rollCallStatusId: null })
  } else {
    res = {}
  }
  dispatch(getRollCallStatusDomains(res?.data || []));
  return dispatch(stopLoading());

};
export const fetchVersionList = (rollCallStatusDomains, domains, productTypes, callWithUser) => async (dispatch) => {
  console.debug('fetchVersionList...')
  dispatch(startLoading());
  dispatch(getVersionList([]));
  const currentuser = JSON.parse(localStorage.getItem('userInfo'));
  let res
  let err
  

  let decoded = {};

  if (currentuser) {
    decoded = jwtDdecode(currentuser.access_token);
  } else {
    decoded.name = "";
    decoded.email = "";
  }

  if (USE_API) {
    const API_URI_CALL = (callWithUser) ? `${API_URL}/api/v1/portal/dataRoll/versionList?userName=`+ decoded.email :`${API_URL}/api/v1/portal/dataRoll/versionList`;
    const fetchData = async () => axiosInstance.post(API_URI_CALL, {
      statusList: rollCallStatusDomains || [],
      domainList: domains || [],
      products: productTypes || [],
    });
    [res, err] = await executor.executeAsync(fetchData)
    if (err) {
      dispatch(openAlert({ message: "Errore durante il recupero della lista delle versioni: " + err.error, type: "danger" }));
    }

  } else {
    //to Load local json without API
  }

  console.debug('fetchVersionList res', res);
  dispatch(getVersionList(res?.data || []));
  return dispatch(stopLoading());

};
export const fetchProductTypes = () => async (dispatch) => {
  console.debug('DataRollCall::fetchProductTypes');
  dispatch(startLoading());
  dispatch(getProductTypes([]));
  let res = null
  let err = null
  
  if (USE_API) {
    const API_URI_CALL = `${API_URL}/api/v1/portal/dataRoll/productTypes`;
    const fetchData = async () => axiosInstance.get(API_URI_CALL);
    [res,err] = await executor.executeAsync(fetchData);
    if (err) {
      dispatch(openAlert({ message: "Errore durante il recupero dei tipi di prodotto: " + err.error, type: "danger" }));
    }
    res?.data?.unshift({productName: "Tutti", productId: null })
  } else {
    res = {}
  }
  dispatch(getProductTypes(res?.data || []));
  return dispatch(stopLoading());

};
export const fetchActivitiesStatusCount = () => async (dispatch) => {
  console.debug('DataRollCall::fetchActivitiesStatusCount');

  dispatch(startLoading());
  dispatch(getActivitiesStatusCount([]));
  const currentuser = JSON.parse(localStorage.getItem('userInfo'));
  let decoded = {};

  if (currentuser) {
    decoded = jwtDdecode(currentuser.access_token);
  } else {
    decoded.name = '';
    decoded.email = '';
  }
  let res = null
  let err = null
  
  if (USE_API) {

    const API_URI_CALL = `${API_URL}/api/v1/portal/dataRoll/activitiesStatusCount?userName=`+ decoded.email;
    const fetchData = async () => axiosInstance.post(API_URI_CALL);
    [res,err] = await executor.executeAsync(fetchData);
    if (err) {
      dispatch(openAlert({ message: "Errore durante il recupero delle attività: " + err.error, type: "danger" }));
    }
  } else {
    res = {}
  }
  dispatch(getActivitiesStatusCount(res?.data || []));
  return dispatch(stopLoading());

};
export const fetchActivitiesCount = () => async (dispatch) => {
  console.debug('DataRollCall::fetchActivitiesCount');
  dispatch(startLoading());
  dispatch(getActivitiesCount([]));
  const currentuser = JSON.parse(localStorage.getItem('userInfo'));
  let decoded = {};

  if (currentuser) {
    decoded = jwtDdecode(currentuser.access_token);
  } else {
    decoded.name = '';
    decoded.email = '';
  }

  let res = null
  let err = null

  if (USE_API) {

    const API_URI_CALL = `${API_URL}/api/v1/portal/dataRoll/activitiesCount?userName=`+ decoded.email;
    const fetchData = async () => axiosInstance.post(API_URI_CALL);
    [res, err] = await executor.executeAsync(fetchData);
    if (err) {
      dispatch(openAlert({ message: "Errore durante il recupero della activities count: " + err.error, type: "danger" }));
    }
  } else {
    res = {}
  }
  dispatch(getActivitiesCount(res?.data || 0));
  return dispatch(stopLoading());

};


export default DataRollCallSliceSlice.reducer;
