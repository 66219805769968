import { useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Container,
	Row,
	Col,
	Label,
	Form
} from "reactstrap";
import "../assets/scss/kendo-theme-custom.scss";

import {
	Chart,
	ChartCategoryAxis,
	ChartCategoryAxisItem,
	ChartSeries,
	ChartSeriesItem,
	ChartValueAxis,
	ChartValueAxisItem
} from '@progress/kendo-react-charts';
import "react-hammerjs";

import moment from "moment";
import LoaderSpin from "../components/loaderSpin/LoaderSpin";

import { useLocation, useNavigate } from "react-router-dom";
import DomainsSelector from "../components/DataQuality/DomainsSelector";
import FilterSubmit from "../components/DataQuality/FilterSubmit";
import { fetchStatusCount,setFilterStatusCount } from "../store/app/DataRollCall/DataRollCallSlice";
import FilterOverlay from "../components/filterOverlay/FilterOverlay";


const DataRollCall = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const searchParams = new URLSearchParams(location.search);


	const isSpinnerVisible = useSelector(  (state) => state.dataRollCallReducer.loading )
  const dataCheck = useSelector( (state) => state.dataQualityReducer.dataCheck ) 
	const filterDomains = useSelector( (state) => state.dataQualityReducer.filterDomains )
	const activeFilter = useSelector( (state) => state.dataRollCallReducer.activeFilter )

	
	const statusCount = useSelector((state) => state.dataRollCallReducer.statusCount )

	useEffect(() => {
		dispatch(fetchStatusCount(filterDomains))
	
	}, [activeFilter]);

	const onSeriesClick = (serie) => {
		console.log (serie)
		const data = statusCount[serie.point.categoryIx]
		console.log('data', data)
		dispatch(setFilterStatusCount([data.roll_call_status_id]))
		searchParams.set('rollcallstatus', JSON.stringify([data.roll_call_status_id]));
		(data.roll_call_status_id == 1 || data.roll_call_status_id == 2) ? 
		navigate('/data-roll-call/detail/product-type-list-roll-call?' + searchParams.toString()) : 
		navigate('/data-roll-call/detail/version-list-roll-call?' + searchParams.toString())

	}


	return (
    <>
      <Form>
        <Row className="bg-light-primary px-4 pt-2">
          <Col>
            <Row className="g-3">
              <Col md="6">
                <div className="mb-2">
                  <Label for="categoryName">Data Domain</Label>
                  <DomainsSelector />
                </div>
              </Col>
              <Col md="auto" className="pt-1">
                <FilterSubmit color="primary" className="mt-3" text="Filtra" />
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>

      <Container fluid className="py-2 px-4  position-relative ">
        <Row className="row g-3 mb-3">
          <Col>
            <div className="py-2 px-3">
              <p className="my-2 lh-sm">
                Generato il {moment().format("D/M/Y")} alle{" "}
                {moment().format("H:m:s")}. Roll-Call totali:{" "}
                {statusCount.reduce((acc, item) => acc + item.count, 0)}.
              </p>
              <Chart
                onSeriesClick={onSeriesClick}
                style={{
                  height: 460,
                }}
              >
                <ChartCategoryAxis>
                  <ChartCategoryAxisItem
                    categories={statusCount?.map(
                      (item) => item.roll_call_status_name
                    )}
                  ></ChartCategoryAxisItem>
                </ChartCategoryAxis>
                <ChartSeries>
                  <ChartSeriesItem
                    style={{ cursor: "pointer" }}
                    type="bar"
                    gap={0.5}
                    spacing={0.25}
                    data={statusCount?.map((item) => item.count)}
                    color={(point) => statusCount[point.index].info.color}
                  />
                </ChartSeries>
                <ChartValueAxis>
                  <ChartValueAxisItem
                    majorUnit={1}
                  />
                </ChartValueAxis>
              </Chart>
            </div>
          </Col>
        </Row>

        <LoaderSpin isVisible={isSpinnerVisible} />

        {/* <div className="filter_results_mask d-flex align-content-center flex-wrap justify-content-center">
					<div className=" p-4 text-light text-center">
						<p className="fs-3">
							Applica i filtri selezionati per aggiornare.</p>
						<p className="fs-3 mt-4">
							Oppure, resetta i filtri per riportarli allo stato coerente con i risultati mostrati.</p>
					</div>
				</div> 
				Questa sarebbe la maschera da sovrapporre ai risultati, credo che dovremmo trasformarla in un componente a cui passare un parametro acceso/spento in base alla coerenza tra filtri e risultati
				*/}

        <LoaderSpin isVisible={isSpinnerVisible} />
        <FilterOverlay isVisible={dataCheck} />
      </Container>
    </>
  );
};

export default DataRollCall;
