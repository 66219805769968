import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  fetchOtherVersions,
  setFilterOtherVersion,
  toogleDataCheck,
} from "../../store/app/DataQualityStatus/DataQualitySlice";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import moment from "moment"

const OtherVersionSelector = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const refreshCount = useSelector(
    (state) => state.dataQualityReducer.refreshCount
  );
  const { versionId } = useParams();


  useEffect(() => {
    dispatch(fetchOtherVersions(versionId));
    // get parameters from url query
    let value;
    try {
      value = JSON.parse(searchParams.get("otherVersion")) || [];
    } catch (error) {
      value = props.defaultValue || [];
      console.error(error);
    }

    dispatch(setFilterOtherVersion(value));
  }, [navigate, refreshCount]);

  const otherVersions = useSelector(
    (state) => state.dataQualityReducer.otherVersions
  );

  const stateValue = useSelector(
    (state) => state.dataQualityReducer.filterOtherVersion
  );

  /**
   * Gestisce il cambio selezione
   * @param {*} event
   */
  const handleChange = (event) => {
    dispatch(setFilterOtherVersion(event.target.value));
    dispatch(toogleDataCheck(true));
  };

  /**
   * Render di un elemento
   * @param {*} li
   * @param {*} itemProps  L'elemento da visualizzare
   * @returns
   */
  const itemRender = (li, itemProps) => {
    const itemChildren = (
      <td>
        &nbsp;
        {itemProps?.dataItem?.version_id +
          " - " +
          moment(itemProps?.dataItem?.reference_date).format("DD/MM/Y")}
      </td>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };

  const valueRender = (li, itemProps) => {
   
    const itemChildren = <span>&nbsp;{ itemProps?.version_id + " - " +  moment(itemProps?.reference_date).format("DD/MM/Y") }  </span>;
    
    return React.cloneElement(
      li, li.props, itemChildren
    );
  };


  return (
    <DropDownList
      data={otherVersions}
      valueRender={valueRender}
      dataItemKey="version_id"
      value={stateValue}
      onChange={handleChange}
      itemRender={itemRender}
    />
  );
};

export default OtherVersionSelector;
