import {GridColumnMenuFilter } from '@progress/kendo-react-grid';


const isColumnActive = (field, dataState) => {
  return (
    GridColumnMenuFilter.active(field, dataState.filter)
  );
};

export const ColumnProps = (field, dataState) => {
    return {
      field: field,
      headerClassName: isColumnActive(field, dataState) ? "active" : "",
    };
  };

export default ColumnProps;