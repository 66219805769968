import {  useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { process } from "@progress/kendo-data-query";
import {
  Container,
  Row,
  Col,
  Label,
  Form,
  Button} from "reactstrap";

import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { ColumnMenu } from '../components/kendoComponents/columnMenu/ColumnMenu';
import { ColumnProps } from '../components/kendoComponents/columnProps/ColumnProps';
import { GridPager } from "../components/kendoComponents/gridPager/GridPager";
import "../assets/scss/kendo-theme-custom.scss";
import moment from "moment";


import "react-hammerjs";


import LoaderSpin from "../components/loaderSpin/LoaderSpin";

import {  useNavigate } from "react-router-dom";
import IssueTypesSelector from "../components/DataQuality/IssueTypesSelector";

import FilterMese from "../components/DataQuality/FilterMese";
import FilterSubmit from "../components/DataQuality/FilterSubmit";
import { fetchDQIssues } from "../store/app/ProcessRuns/ProcessRunsSlice";
import { DateCell } from "../components/kendoComponents/dateCell/DateCell";
import FilterOverlay from "../components/filterOverlay/FilterOverlay";





const DataQualityIssueReport = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);

  const isSpinnerVisible = useSelector((state) => state.dataQualityReducer.loading);
  const dataCheck = useSelector((state) => state.dataQualityReducer.dataCheck);
  const refreshCount = useSelector((state) => state.dataQualityReducer.refreshCount)

  const fileterDQIssueTypes = useSelector((state) => state.processRunsReducer.filterDQIssueTypes);
  const dqIssues =  useSelector((state) => state.processRunsReducer.dqIssues);  
  const filterMese = useSelector((state) => state.dataQualityReducer.filterMese)


  const [dataState, setDataState] = useState({
    sort: [
      {
        field: "code",
        dir: "asc",
      },
    ], skip: 0, take: 20
  });

  const [dataResult, setDataResult] = useState(
    process(dqIssues, dataState)
  );
  
  useEffect(() => {    
    dispatch(fetchDQIssues(filterMese, fileterDQIssueTypes))    
  }, [navigate, refreshCount]);

  useEffect(() => {
    setDataResult(process(dqIssues, dataState));
  }, [dqIssues])


  return (
    <>
      <Form>
        <Row className="bg-light-primary px-4 pt-2">
          <Col>
            <Row className="g-3">
              <Col md="6">
                <div className="mb-2">
                  <Label for="categoryName">Mese report</Label>
                  <FilterMese />
                </div>
              </Col>
              <Col>
                <div className="mb-2">
                  <Label for="categoryName">DQ Issue type</Label>
                  <IssueTypesSelector />
                </div>
              </Col>

              <Col md="auto" className="pt-1">
                <FilterSubmit color="primary" className="mt-3" text="Filtra" />
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>

      <Container fluid className="py-2 px-4  position-relative ">
        <p className="my-2 lh-sm">
          Generato il {moment().format("D/M/Y")} alle {moment().format("H:m:s")}
          . DQ-Issuses totali: {dqIssues.length}.
        </p>
        <Row>
          <Col>
            <div className="grid_task_container_tabs border overflow-x-scroll position-relative">
              <div className="position-absolute w-100 h-100 top-0 start-0">
                <Grid
                  style={{ height: "100%" }}
                  scrollable="true"
                  fixedScroll={true}
                  pageable={true}
                  sortable={true}
                  total={dataResult?.length}
                  data={dataResult}
                  pager={(props) => GridPager(props, dqIssues, 'dq_issue_report_')}
                  {...dataState}
                  groupable={true}
                  onDataStateChange={(e) => {
                    setDataState(e.dataState);
                    setDataResult(process(dqIssues, e.dataState));
                  }}
                >
                  <Column
                    {...ColumnProps("issue_type_name", dataState)}
                    title="Data quality issue type"
                    cell={(props) => (
                      <td>
                        <span className="fw-bold">
                          {props.dataItem.issue_type_name}
                        </span>
                      </td>
                    )}
                    columnMenu={ColumnMenu}
                    width="300px"
                  />
                  <Column
                    {...ColumnProps("version_id", dataState)}
                    title="ID versione"
                    columnMenu={ColumnMenu}
                    width="150px"
                    cell={(props) => (
                      <td>
                        <a
                          style={{ cursor: "pointer" }}
                          className="fw-bold text-decoration-underline"
                          onClick={(event) => {
                            navigate(
                              "/versioni/detail/" +
                                props.dataItem.version_id +
                                "?" +
                                searchParams
                            );
                          }}
                          onKeyDown={() => console.log("onKeyDown")}
                        >
                          {props.dataItem.version_id}
                        </a>
                      </td>
                    )}
                  />
                  <Column
                    {...ColumnProps("product_name", dataState)}
                    title="Data product type name"
                    columnMenu={ColumnMenu}
                    width="200px"
                    cell={(props) => (
                      <td>
                        <a
                          style={{ cursor: "pointer" }}
                          className="fw-bold text-decoration-underline"
                          onClick={(event) => {
                            //if (!props.dataItem.typeAtlanProductId)
                              window.open(process.env.REACT_APP_ATLAN_PRE + props.dataItem.typeAtlanProductId + process.env.REACT_APP_ATLAN_POST , "_blank");
                          }}
                        >
                          {props.dataItem.product_name}
                        </a>
                      </td>
                    )}
                  />
                  <Column
                    {...ColumnProps("reference_date", dataState)}
                    title="Data riferimento"
                    columnMenu={ColumnMenu}
                    cell={(props) => DateCell(props, false)}
                    width="200px"
                  />
                  <Column
                    {...ColumnProps("status_name", dataState)}
                    title="Stato versione"
                    columnMenu={ColumnMenu}
                    width="200px"
                  />
                  <Column
                    {...ColumnProps("creation_ts", dataState)}
                    title="Data creazione"
                    columnMenu={ColumnMenu}
                    minResizableWidth="200px"
                  />
                  <Column
                    {...ColumnProps("validation_ts", dataState)}
                    title="Timestamp validazione"
                    columnMenu={ColumnMenu}
                    cell={(props) => DateCell(props, true)}
                    width="200px"
                  />
                  <Column
                    {...ColumnProps("is_manual_validation", dataState)}
                    title="Tipo Validazione"
                    columnMenu={ColumnMenu}
                    width="200px"
                    cell={(props) => (
                      <td>
                        {props.dataItem.is_manual_validation
                          ? "Manuale"
                          : "Automatica"}
                      </td>
                    )}
                  />
                  <Column
                    {...ColumnProps("validation_user", dataState)}
                    title="Utente validazione"
                    columnMenu={ColumnMenu}
                    width="200px"
                  />
                  <Column
                    {...ColumnProps("domain_name", dataState)}
                    title="Dominio"
                    columnMenu={ColumnMenu}
                    width="200px"
                  />
                </Grid>
              </div>
            </div>
          </Col>
        </Row>

        <LoaderSpin isVisible={isSpinnerVisible} />
        <FilterOverlay isVisible={dataCheck} />

        {/* <div className="filter_results_mask d-flex align-content-center flex-wrap justify-content-center">
          <div className=" p-4 text-light text-center">
            <p className="fs-3">
              Applica i filtri selezionati per aggiornare.</p>
            <p className="fs-3 mt-4">
              Oppure, resetta i filtri per riportarli allo stato coerente con i risultati mostrati.</p>
          </div>
        </div> 
        Questa sarebbe la maschera da sovrapporre ai risultati, credo che dovremmo trasformarla in un componente a cui passare un parametro acceso/spento in base alla coerenza tra filtri e risultati
        */}
      </Container>
    </>
  );
};

export default DataQualityIssueReport;
