import PropTypes from "prop-types"
import { useEffect } from "react";
import {useDispatch, useSelector } from "react-redux";
import {  useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import {Input} from "reactstrap";
import { setFilterDataA, setFilterDataDa, toogleDataCheck, }  from "../../store/app/DataQualityStatus/DataQualitySlice";


const FilterDataA = ( props ) => {
  const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const refreshCount = useSelector(  (state) => state.dataQualityReducer.refreshCount   )

	const INTERVAL_MIN_MAX_DAYS = process.env.REACT_APP_INTERVAL_MIN_MAX_DAYS || 90
	const DATE_GAP_HOURS = process.env.REACT_APP_DATE_GAP_HOURS || 24

  const disabled = useSelector( (state) => state.dataQualityReducer.filterIntervallo != -1 );
  const value = useSelector( (state) => state.dataQualityReducer.filterDataA  || 0);
  const dataDa = useSelector( (state) => state.dataQualityReducer.filterDataDa);

	useEffect(() => {
		// get parameters from url query
		const valueNew = searchParams.get('toDate') || value || props.defaultValue 
		dispatch (setFilterDataA(valueNew))
  }, [navigate, refreshCount]);


	const changed = ((event) => {
		const days = moment(event.target.value).diff(moment(dataDa), 'days')
		if (days >= INTERVAL_MIN_MAX_DAYS){
			dispatch (setFilterDataDa(moment(event.target.value).subtract(INTERVAL_MIN_MAX_DAYS, 'days').format ('YYYY-MM-DD 00:00:00')))
		}

		// se la "data a" è precedeente a "data da", aggiusta secondo il gap configurato
		if (moment(event.target.value).valueOf() <= moment(dataDa).valueOf()){
			dispatch (setFilterDataDa(moment(event.target.value).subtract(DATE_GAP_HOURS, 'hours').format ('YYYY-MM-DD HH:mm:ss')))
		}


		dispatch (toogleDataCheck(true))
		dispatch (setFilterDataA(event.target.value))

	})

	if (disabled){
		return(
			<Input disabled type="datetime-local" name={props.name}  value={value} placeholder="-" style={{color: "transparent"}} /> 
		)
	} 
	return(
		<Input type="datetime-local" name={props.name} placeholder="-"  value={value}  onChange={ changed }/>
	)
}

FilterDataA.propTypes = {
  defaultValue: PropTypes.any,
  name: PropTypes.any
}



export default FilterDataA
