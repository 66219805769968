import axios from "axios";

const API_URL = `${process.env.REACT_APP_API_OVERX_URL}/api/v1/sicurezza/`;

const login = async (code, codeVerifier) => {
  try {
    const response = await axios
      .post(`${API_URL}login-code`, {
        code: code,
        redirectUri: window.location.origin + localStorage.getItem("lastKnownPath"),
        codeVerifier: codeVerifier,
      });
    if (response.data.access_token) {
      localStorage.setItem("userInfo", JSON.stringify(response.data));
      localStorage.removeItem("codeVerifier");
      localStorage.removeItem("codeChallenge");
    }
    return response.data;
  } catch (e) {
    console.log("Err on loginAuth ", e);
    return e;
  }
};

const AuthServiceSSO = {
  login,
};

export default AuthServiceSSO;
