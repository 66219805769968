import PropTypes from "prop-types"
import React, { useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import {  useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { Input} from "reactstrap";
import {setFilterDataDa, setFilterDataA,toogleDataCheck }  from "../../store/app/DataQualityStatus/DataQualitySlice";


const FilterDataDa = ( props ) => {
  const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);

	const refreshCount = useSelector(  (state) => state.dataQualityReducer.refreshCount   )
	const filterIntervallo = useSelector(  (state) => state.dataQualityReducer.filterIntervallo || 8)

	const INTERVAL_MIN_MAX_DAYS = process.env.REACT_APP_INTERVAL_MIN_MAX_DAYS || 90

	
	const DATE_GAP_HOURS = process.env.REACT_APP_DATE_GAP_HOURS || 24

  const disabled = useSelector( (state) => state.dataQualityReducer.filterIntervallo != -1 );
  const dataA = useSelector( (state) => state.dataQualityReducer.filterDataA);
  const value = useSelector( (state) => state.dataQualityReducer.filterDataDa || 0);


	useEffect(() => {
		// get parameters from url query
		const valueNew = searchParams.get('fromDate') || value || props.defaultValue
		dispatch (setFilterDataDa(valueNew))
  }, [navigate, refreshCount]);


	const changed = ((event) => {
		dispatch (setFilterDataDa(event.target.value))
		const days = moment(dataA).diff(moment(event.target.value), 'days')
		if (days >= INTERVAL_MIN_MAX_DAYS){
			dispatch (setFilterDataA(moment(event.target.value).add(INTERVAL_MIN_MAX_DAYS, 'days').format ('YYYY-MM-DD 23:59:59')))
		}
		if (days < 0){
			dispatch (setFilterDataA(event.target.value))
		}
		// se la "data dal" è più avanti di "data a", aggiusta secondo il gap configurato
		if (moment(event.target.value).valueOf() >= moment(dataA).valueOf()){
			dispatch (setFilterDataA(moment(event.target.value).add(DATE_GAP_HOURS, 'hours').format ('YYYY-MM-DD HH:mm:ss')))
		}
		dispatch (toogleDataCheck(true))
	})

	if (disabled){
		return(
			<Input disabled type="datetime-local" name={props.name}  value={value} placeholder="-" style={{color: "transparent"}} />
		)
	} 
	return(
		<Input type="datetime-local" name={props.name} placeholder="-"  value={value}  onChange={ changed } />
	)
}

FilterDataDa.propTypes = {
  defaultValue: PropTypes.any,
  name: PropTypes.any
}



export default FilterDataDa;