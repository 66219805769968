import PropTypes from "prop-types";
import { Pager } from "@progress/kendo-react-data-tools";
import FeatherIcon from "feather-icons-react";
import { Button, Col } from "reactstrap";
import { CSVLink } from "react-csv";

import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { exportPayload } from "../../../store/app/DataQualityStatus/DataQualitySlice";
import { requestDownload } from "../../../store/app/DownloadManager/DownloadManagerSlice";

import moment from "moment";

export const GridPager = (props, data, pageName = "") => {
  const dispatch = useDispatch();
  const { versionId } = useParams();

  const currentPage = Math.floor(props.skip / props.take) + 1;
  const totalPages = Math.ceil((props.total || 0) / props.take);

  const diff = props.total - props.skip;
  const startItem = props.total > 0 ? Math.max(props.total - diff + 1, 1) : 0;

  // Calcola endItem senza superare props.total
  const endItem = Math.min(startItem + props.take - 1, props.total);

  const exportBackend = () => {
    //dispatch(exportPayload(versionId));
    dispatch(requestDownload(versionId));
  };

  return (
    <div
      className={"k-pager k-pager-md k-grid-pager"}
      style={{
        borderTop: "1px solid",
        borderTopColor: "inherit",
      }}
    >
      <Pager
        skip={props.skip}
        take={props.take}
        total={props.total}
        type={props.type}
        pageSizes={props.pageSizes ? props.pageSizes : undefined}
        previousNext={props.previousNext}
        onPageChange={props.onPageChange}
      />

      {totalPages > 0 && (
        <div className="col-4">{`Page ${currentPage} of ${totalPages}`}</div>
      )}
      <Col md="auto" className="ms-auto my-3 me-3">
        {pageName == "payload" ? (
          <Button onClick={exportBackend} color="primary" outline>
            Esporta in CSV
            <FeatherIcon icon="download" size={16} className="mx-1 align-top" />
          </Button>
        ) : (
          <CSVLink
            separator=";"
            data={data}
            filename={pageName != "" ? `${pageName + moment().format ('YYYYMM_YYYYMMHHmmss')}.csv` : "gridExport.csv"}
          >
            <Button color="primary" outline>
              Esporta in CSV
              <FeatherIcon
                icon="download"
                size={16}
                className="mx-1 align-top"
              />
            </Button>
          </CSVLink>
        )}
      </Col>
      <div className="">{`${startItem} - ${endItem} of ${props.total} items `}</div>
    </div>
  );
};

GridPager.propTypes = {
  onPageChange: PropTypes.any,
  pageSizes: PropTypes.any,
  previousNext: PropTypes.any,
  skip: PropTypes.any,
  take: PropTypes.any,
  total: PropTypes.number,
  type: PropTypes.any,
};

export default GridPager;
