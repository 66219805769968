import React, { Link, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  useLocation, useNavigate } from "react-router-dom";
import { setFilterDomains, fetchDomains ,toogleDataCheck} from "../../store/app/DataQualityStatus/DataQualitySlice";
import { MultiSelect } from "@progress/kendo-react-dropdowns";


const DomainsSelector = () => {
  const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const refreshCount = useSelector(  (state) => state.dataQualityReducer.refreshCount   )

  useEffect(() => {
    dispatch(fetchDomains());
  }, [dispatch]);

	useEffect(() => {
		// get parameters from url query
		let value
		try{
			value = JSON.parse(searchParams.get('domains')) || []
		}catch (error){
			value = props.defaultValue || []
			console.error (error)
		}

    dispatch (setFilterDomains (value))
  }, [navigate, refreshCount]);



  const domains = useSelector( (state) => state.dataQualityReducer.domains );

  const stateValue = useSelector( (state) => state.dataQualityReducer.filterDomains );

  let selectedIds = domains?.reduce ((accumulator, item) => { 
    if (stateValue?.findIndex ((sparam) => sparam == item.domainId) != -1){
      accumulator.push (item.domainId)
    }
    return accumulator
  }, [] )


  /**
   * Gestisce il cambio selezione
   * @param {*} event 
   */
  const handleChange = (event) => {

    const currentSelectAll = event.value.some((i) => i.domainId === null);

    if (currentSelectAll){
      const tutti = event.value.length == domains.length
      if (tutti){
        selectedIds = []
      } else {
        selectedIds = domains.map ( (item) => item.domainId)
        selectedIds.shift()        
      }
    } else {
      selectedIds = event.value.map ( (item) => item.domainId)
      // if (selectedIds.length == 0){
      //   setAllSelected (false)
      // }

    }

    // console.log ('DomainsSelector::handleChange (selectedIds)', selectedIds)
    dispatch (setFilterDomains (selectedIds))
    dispatch (toogleDataCheck(true))
  };

  /**
   * Render di un elemento
   * @param {*} li 
   * @param {*} itemProps  L'elemento da visualizzare
   * @returns 
   */
  const itemRender = (li, itemProps) => {
    const itemChildren = (
      <span className="form-check py-1">
					<input
            id={`filter_domain_id_${itemProps.index}`}
						type="checkbox"
						checked={itemProps.selected}
						onChange={(e) => itemProps.onClick(itemProps.index, e)}
						className="form-check-input"
					/>
          <label className="form-check-label">
					&nbsp;{itemProps.dataItem.domainDescription}
				</label>
      </span>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };


  const value = domains?.reduce ((accumulator, item) => { 
    if (selectedIds.findIndex ((sparam) => sparam == item.domainId) != -1){
      accumulator.push (item)
    }
    return accumulator
  }, [] )

  const selectedCount = value.length;

	return (
    <MultiSelect
      data={domains}
      autoClose={false}
      itemRender={itemRender}
      value={value}
      onChange={handleChange}
      dataItemKey="domainId"
      textField="domainDescription"
      tags={
        selectedCount > 0
          ? [{ text: `${selectedCount} domini selezionati`, data: [...value] }]
          : []
        }
      placeholder="Tutti"
    />

	)
};

export default DomainsSelector;