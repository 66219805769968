import { Alert } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { closeAlert } from "../../store/app/DataQualityStatus/DataQualitySlice";
import { exportPayloadWithSocket } from "../../store/app/DownloadManager/DownloadManagerSlice";
import ProgressBar from "../progressBar/ProgressBar";

const AlertProvider = () => {
  const dispatch = useDispatch();

  const clickToDownload = () => {
    dispatch(exportPayloadWithSocket(alertValues.requestId));
  };

  const alertValues = useSelector(
    (state) => state.dataQualityReducer.alertValues
  );

  const isProgressBarVisible = useSelector(
    (state) => state.downloadManagerReducer.progressBarShow
  );

  const downloadProgress = useSelector(
    (state) => state.downloadManagerReducer.downLoadProgress
  );

  const handleClose = () => {
    dispatch(closeAlert());
  };

  useEffect(() => {
    if (alertValues.isOpen) {
      setTimeout(() => {
        handleClose();
      }, 10000);
    }
  }, [alertValues.isOpen]);

  return (
    <div
      style={{ position: "fixed", top: "20px", right: "20px", zIndex: "9999" }}
    >
      <Alert
        color={alertValues.type}
        isOpen={alertValues.isOpen}
        toggle={handleClose}
      >
        {alertValues.clickable ? (
          <div onClick={clickToDownload} style={{ cursor: "pointer" }}>
            {alertValues.message}
          </div>
        ) : (
          alertValues.message
        )}
      </Alert>
      <ProgressBar
        isVisible={isProgressBarVisible}
        value={downloadProgress}
      />
    </div>
  );
};

export default AlertProvider;
