import React,{ useEffect, useState } from "react";
import { process } from "@progress/kendo-data-query";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Row,
  Col,
  Label,
  Form,
  Button
} from "reactstrap";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import moment from "moment";
import FeatherIcon from "feather-icons-react";
import { ColumnProps } from "../components/kendoComponents/columnProps/ColumnProps";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import LoaderSpin from "../components/loaderSpin/LoaderSpin";

import { SetCurrentActiveTab } from "../store/customizer/CustomizerSlice";
import ModalManualValidation from "../components/manualValidation/ModalManualValidation";
import "../assets/scss/kendo-theme-custom.scss";

import {
  fetchProcessRunLogs,
  fetchProcessVersionRunInfo,
} from "../store/app/ProcessRuns/ProcessRunsSlice";

const ApplicationLog = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { versionId } = useParams();
  
  const searchParams = new URLSearchParams(location.search);


  const logs = useSelector((state) => state.processRunsReducer.processRunLogs);
  const processVersionRunInfo = useSelector(
    (state) => state.processRunsReducer.processVersionRunInfo || {}
  );
  const isSpinnerVisible = useSelector(
    (state) => state.dataQualityReducer.loading
  );
  const refreshCount = useSelector(
    (state) => state.dataQualityReducer.refreshCount
  );

  const [dataState, setDataState] = useState({
    sort: [
      {
        field: "code",
        dir: "asc",
      },
    ],
    skip: 0,
    take: 20,
  });

  const [dataResult, setDataResult] = useState(process(logs, dataState));

  useEffect(() => {
    dispatch(fetchProcessVersionRunInfo(versionId));
    dispatch(fetchProcessRunLogs(versionId));
  }, [navigate, refreshCount]);

  useEffect(() => {
    setDataResult(process(logs, dataState));
  }, [logs]);

  const currentActiveTab = useSelector(
    (state) => state.customizer.currentActiveTab
  );

  // Open modal window
  const toggleManualValidation = () => {
    setModal(!modal);
  };

  const onClickPrefect = (event) => {
    if (processVersionRunInfo.run_handle) {
      window.open(process.env.REACT_APP_PREFECT_URL + processVersionRunInfo.run_handle, "_blank"); 
    }
  };

  // Toggle active state for Tab
  const toggle = (tab) => {
    if (currentActiveTab !== tab) dispatch(SetCurrentActiveTab(tab));
  };

  const [modal, setModal] = useState(false);

  return (
    <>
      <Form>
        <Row className="bg-light-primary px-4 py-2">
          <Col>
            <Row>
              <Col md="3" lg="2" className="d-flex">
                <h5 className="form-row-title">Output Version Info</h5>
              </Col>
              <Col md="auto">
                <Label for="outputVersionID">Versione</Label>
                <button
                  id="outputVersionID"
                  className="pt-0 form-control-plaintext hv-75"
                  onClick={() => {
                    navigate(
                      "/versioni/detail/" +
                        processVersionRunInfo.version_id +
                        "?" +
                        searchParams
                    );
                  }}
                >
                  <span className="px-2 bg-medium-primary border border-primary rounded">
                    {processVersionRunInfo.version_id}
                  </span>
                </button>
              </Col>
              <Col md="auto">
                <Label for="outputVersionProductTypeName">Data Product</Label>
                <p className="pt-0 form-control-plaintext hv-75" role="button">
                  <span
                    id="outputVersionProductTypeName"
                    className="px-2 bg-medium-primary border border-primary rounded"
                    onClick={() => {
                      window.open(
                        process.env.REACT_APP_ATLAN_PRE +
                          processVersionRunInfo.product_type_handle +
                          process.env.REACT_APP_ATLAN_POST,
                        "_blank"
                      );
                    }}
                  >
                    {processVersionRunInfo.product_name}
                  </span>
                </p>
              </Col>
              <Col md="auto">
                <Label for="outputVersionReferenceDate">Data riferimento</Label>
                <p
                  id="outputVersionReferenceDate"
                  className="pt-0 form-control-plaintext"
                >
                  {moment(processVersionRunInfo.reference_date).format(
                    "DD/MM/YYYY"
                  )}
                </p>
              </Col>
              <Col>
                <Label for="outputVersionExtendedStatus">Stato versione</Label>
                <p
                  id="outputVersionExtendedStatus"
                  className="pt-0 form-control-plaintext"
                >
                  {processVersionRunInfo.status_name} -{" "}
                  {processVersionRunInfo.ext_status_name}
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="bg-very-light-primary px-4 py-2">
          <Col>
            <Row>
              <Col md="3" lg="2" className="d-flex">
                <h5 className="form-row-title">Run info</h5>
              </Col>
              <Col md="auto">
                <Label for="runID">Run ID</Label>
                <p id="runID" className="pt-0 form-control-plaintext">
                  {processVersionRunInfo.run_id}
                </p>
              </Col>
              <Col md="auto">
                <Label for="startTimeStamp">Inizio</Label>
                <p id="startTimeStamp" className="pt-0 form-control-plaintext">
                  {moment(processVersionRunInfo.run_start_ts).format(
                    "DD/MM/YYYY"
                  )}
                </p>
              </Col>
              <Col md="auto">
                <Label for="duration">Durata</Label>
                <p className="pt-0 form-control-plaintext">
                  {processVersionRunInfo.run_status == "Timeouted"
                    ? "Timeouted after "
                    : ""}
                  {processVersionRunInfo.duration}
                </p>
              </Col>
              <Col md="auto">
                <Label for="processName">Processo</Label>
                <p id="processName" className="pt-0 form-control-plaintext">
                  {processVersionRunInfo.process_name}
                </p>
              </Col>
              <Col md="auto">
                <Label for="revisionNumber">Numero revisione</Label>
                <p id="revisionNumber" className="pt-0 form-control-plaintext">
                  {processVersionRunInfo.revision_num}
                </p>
              </Col>
              <Col>
                <Label for="revisionReleaseNotes">Nota revisione</Label>
                <p
                  id="revisionReleaseNotes"
                  className="pt-0 form-control-plaintext"
                >
                  {processVersionRunInfo.release_notes}
                </p>
              </Col>
              {/* <Col md="auto">
								<Label for="revisionReleaseDate">Data revisione</Label>
								<p id="revisionReleaseDate" className="pt-0 form-control-plaintext">
									{moment(processVersionRunInfo.reference_date).format ("DD/MM/YYYY")}
								</p>
							</Col> */}
            </Row>
          </Col>
        </Row>
      </Form>

      <Row className="px-4 my-3">
        <Col md="auto" className="ms-auto">
          <Button color="primary" outline className="" onClick={onClickPrefect}>
            Log Prefect
            <FeatherIcon
              icon="external-link"
              size={16}
              className="mx-1 align-top"
            />
          </Button>
        </Col>
      </Row>

      <Container fluid className="px-4">
        <Row>
          <Col>
            <div className="grid_task_container_short border">
              <Grid
                style={{ height: "100%" }}
                pageable={true}
                sortable={true}
                total={dataResult?.length}
                data={dataResult}
                {...dataState}
                groupable={true}
                onDataStateChange={(e) => {
                  setDataState(e.dataState);
                  setDataResult(process(logs, e.dataState));
                }}
              >
                <GridColumn
                  {...ColumnProps("log_ts", dataState)}
                  title="Timestamp"
                />
                <GridColumn {...ColumnProps("flow", dataState)} title="Flow" />
                <GridColumn
                  {...ColumnProps("task", dataState)}
                  title="Flow Subname"
                />
                <GridColumn
                  {...ColumnProps("message", dataState)}
                  title="Message"
                />
              </Grid>
            </div>
          </Col>
        </Row>
        <LoaderSpin isVisible={isSpinnerVisible} />
      </Container>
      <ModalManualValidation
        modal={modal}
        toggleManualValidation={toggleManualValidation}
      />
    </>
  );
};

export default ApplicationLog;
