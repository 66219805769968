import PropTypes from "prop-types"
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation} from "react-router-dom";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { fetchRollCallStatusDomains, setFilterStatusCount } from "../../store/app/DataRollCall/DataRollCallSlice";
import { toogleDataCheck } from "../../store/app/DataQualityStatus/DataQualitySlice";


const RollCallStatusDomainsSelector = (props) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const refreshCount = useSelector((state) => state.dataQualityReducer.refreshCount)

    useEffect(() => {
        let value
        try {
            value = JSON.parse(searchParams.get('rollcallstatus')) || [];
            (value == 1 || value == 2) ? dispatch(fetchRollCallStatusDomains(false)) : dispatch(fetchRollCallStatusDomains(true))
        }
        catch (error) {
            value = props.defaultValue || []
            console.error(error)
        }
        dispatch(setFilterStatusCount(value))
    }, [dispatch], refreshCount)

    const rollCallStatusDomains = useSelector((state) => state.dataRollCallReducer.rollCallStatusDomains);

    const stateValue = useSelector((state) => state.dataRollCallReducer.filterStatusCountDomains);

    let selectedIds = rollCallStatusDomains?.reduce((accumulator, item) => {
        if (stateValue?.findIndex((sparam) => sparam == item.rollCallStatusId) != -1) {
            accumulator.push(item.rollCallStatusId)
        }
        return accumulator
    }, [])


    /**
     * Gestisce il cambio selezione
     * @param {*} event 
     */
    const handleChange = (event) => {

        const currentSelectAll = event.value.some((i) => i.rollCallStatusId === null);

        if (currentSelectAll) {
            const tutti = event.value.length == rollCallStatusDomains.length
            if (tutti) {
                selectedIds = []
            } else {
                selectedIds = rollCallStatusDomains.map((item) => item.rollCallStatusId)
                selectedIds.shift()
            }
        } else {
            selectedIds = event.value.map((item) => item.rollCallStatusId)
        }

        dispatch(setFilterStatusCount(selectedIds))
        dispatch(toogleDataCheck(true)) 
    };

    /**
     * Render di un elemento
     * @param {*} li 
     * @param {*} itemProps  L'elemento da visualizzare
     * @returns 
     */
    const itemRender = (li, itemProps) => {
        const itemChildren = (
            <span className="form-check py-1">
                <input
                    id={`filter_rollcallstatusdomains_id${itemProps.index}`}
                    type="checkbox"
                    checked={itemProps.selected}
                    onChange={(e) => itemProps.onClick(itemProps.index, e)}
                    className="form-check-input"
                />
                <label className="form-check-label">
                    &nbsp;{itemProps.dataItem.rollCallStatusName}
                </label>
            </span>
        );
        return React.cloneElement(li, li.props, itemChildren);
    };


    const value = rollCallStatusDomains?.reduce((accumulator, item) => {
        if (selectedIds.findIndex((sparam) => sparam == item.rollCallStatusId) != -1) {
            accumulator.push(item)
        }
        return accumulator
    }, [])

    const selectedCount = value.length;

    return (
        <MultiSelect
            data={rollCallStatusDomains}
            autoClose={false}
            itemRender={itemRender}
            value={value}
            onChange={handleChange}
            dataItemKey="rollCallStatusId"
            textField="rollCallStatusName"
            tags={
                selectedCount > 0
                    ? [{ text: `${selectedCount} status selezionati`, data: [...value] }]
                    : []
            }
            placeholder="Tutti"
        />
    )
};

RollCallStatusDomainsSelector.propTypes = {
  defaultValue: PropTypes.array
}

export default RollCallStatusDomainsSelector;