const useAuth = () => {   
  const user = JSON.parse(localStorage.getItem('userInfo'));
 
  if (user && user.access_token) {
    return true;  
  }
  
  return false;
};

export default useAuth;
