export const FilterOverlay = (props) => {

    const filterOverlayToggle = props.isVisible ? 'show' : 'd-none';
    
      return (
        <div className={`filter_results_mask fade ${filterOverlayToggle}`}>
            <div className="position-absolute top-50 start-50 translate-middle">
            <h1 className="text-white text-center">
              Applica i filtri selezionati per aggiornare
            </h1>
            <h2 className="text-white text-center">
              oppure, resetta i filtri per riportarli allo stato coerente con i risultati mostrati.
            </h2>
            </div>
        </div>
      );
    };
    
export default FilterOverlay;