import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';
import simg from '../assets/images/background/Atlan-logo-full.png';


const Atlan = () => {
  return (
    <div
      className="loginBox"
    >
      <div className="d-flex align-items-center justify-content-center h-100">
        <div className="text-center">
          <div className="my-3">
          <img src={simg} alt="icons" className="img-fluid rounded mb-4" />
          </div>

          <Link to="/home" className="btn btn-primary">
            Indietro
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Atlan;
