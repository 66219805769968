import PropTypes from "prop-types";
import React from "react";
import { useRoutes } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import Themeroutes from "./routes/Router";
import "./assets/scss/style.scss";
import ToastContainer from "./components/toasts/ToastContainer";

import AlertProvider  from "./components/alertProvider/AlertProvider";


/*    Handle exception version boundary */
import ErrorGeneric from "./views/ErrorGeneric";

function ErrorFallback({ error }) {
  return (
    <ErrorGeneric
      code={error.message}
      title={error.message}
      message={error.message}
    />
  );
}

ErrorFallback.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.any,
  }),
};

function App() {
  const routing = useRoutes(Themeroutes);

  return (
    <div className="App">
      <ErrorBoundary FallbackComponent={ErrorFallback}>{routing}</ErrorBoundary>
      <ToastContainer />
      <AlertProvider />
    </div>
  );
}

export default App;
