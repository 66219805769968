import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Row,
  Col,
} from "reactstrap";
import "../assets/scss/kendo-theme-custom.scss";

import SectionHeading from "../components/sectionHeading/SectionHeading";
import { Chart, ChartArea, ChartLegend, ChartTooltip, ChartSeries, ChartSeriesItem, } from "@progress/kendo-react-charts";
import "react-hammerjs";

import moment from "moment";
import LoaderSpin from "../components/loaderSpin/LoaderSpin";


import { useLocation, useNavigate } from "react-router-dom";
import { setFilterStatusCount,setCallWithUser, fetchActivitiesStatusCount, fetchActivitiesCount } from "../store/app/DataRollCall/DataRollCallSlice";

const MyActivities = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const refreshCount = useSelector((state) => state.dataQualityReducer.refreshCount)


  const activitiesStatusCount = useSelector((state) => state.dataRollCallReducer.activitiesStatusCount || []);
  const activitiesCount = useSelector((state) => state.dataRollCallReducer.activitiesCount || 0);




  const transform = (activitiesStatusCount) => {
    let data = []
    //Creazione Array Compatibile con KendoChart

    activitiesStatusCount?.forEach((Count) => {
      data.push(Count);
    })

    return [{
      name: "MyActivities",
      data: data

    }]
  }
  const generateLegend = ((series) => {
    return (
      <a key={series.rollCallStatusId} className="legend-line link-dark text-decoration-none hv-75">
        <span className="legend-color" style={{ backgroundColor: series.info.color }}></span>
        <span className="legend-value">
          {series.rollCallStatusName}: {series.count}
        </span>
      </a>
    )
  });
  const mapSeries = ((series) => {
    return (
      <ChartSeriesItem
        key={series.rollCallStatusId}
        animate={false}
        type="donut"
        margin={0}
        startAngle={90}
        size={130}
        name={series.name}
        data={series.data}
        field="count"
        categoryField="rollCallStatusName"
        colorField="info.color"
        padding={10}
        style={{ cursor: 'pointer' }}
      ></ChartSeriesItem>
    )
  })

  const isSpinnerVisible = useSelector(
    (state) => state.dataQualityReducer.loading
  );


  const onSeriesClick = ((event) => {
    

    if(!event?.dataItem?.rollCallStatusId)
      return;

    console.log(event)

    

    dispatch(setCallWithUser(true));
    dispatch(setFilterStatusCount([event.dataItem.rollCallStatusId]));

    searchParams.set("rollcallstatus",JSON.stringify([event.dataItem.rollCallStatusId]));
    

    (event.dataItem.rollCallStatusId == 1 || event.dataItem.rollCallStatusId == 2) ? 
    navigate('/data-roll-call/detail/product-type-list-roll-call?' +  searchParams.toString()): 
		navigate('/data-roll-call/detail/version-list-roll-call?' + searchParams.toString()) 
  })

  const renderTooltip = (context) => {
    const { category, series, value } = context.point || context;

    if (series.name == 'Empty') {
      return (<div>
        Nessun' attività
      </div>
      );
    } else
      return (
        <div style={{ padding: 24 + 'px' }}>
          {category}: {value}
        </div>
      );
  };

  const renderEmpty = () => {
    return (
      <ChartSeriesItem
        type="pie"
        name={'Empty'}
        data={[{ value: 1, color: 'grey' }]}
        field="value"
        colorField={'color'}
        labels={{
          visible: false
        }}
      />

    )
  }

  useEffect(() => {

    dispatch(fetchActivitiesStatusCount());
    dispatch(fetchActivitiesCount());


  }, [navigate, refreshCount]);
  return (
    <Container fluid className="py-2 px-4  position-relative ">
      <Row className="row mb-3">
        <Col>
          <SectionHeading
            iconClass="pie-chart"
            headingTitle="Conteggio per Roll-call status"
            subHeadingTitle={
              "Generato il " +
              moment().format("D/M/Y") +
              " alle " +
              moment().format("HH:mm") +
              ". Attività totali:  " + activitiesCount}  //da sistemare - valore NaN?

          />
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col className="pie-container">
          <Chart onSeriesClick={onSeriesClick}>
            <ChartTooltip render={renderTooltip} />
            <ChartLegend visible={false} />
            <ChartArea background="none" height={500} />
            <ChartSeries>
              {(activitiesStatusCount.findIndex(x => x.count > 0) == -1) ? renderEmpty() : transform(activitiesStatusCount)?.map(mapSeries)}

            </ChartSeries>
          </Chart>
        </Col>
        <Col>
          <Row className="legend-container">
            <Col>
              <div className="legend-group mt-3">
                <h5 className="legend-title">
                  Open
                </h5>
                {activitiesStatusCount.filter(x => !x.expired).map(generateLegend)}
              </div>
              <div className="legend-group mt-3">
                <h5 className="legend-title">
                  Expired
                </h5>
                {activitiesStatusCount.filter(x => x.expired).map(generateLegend)}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>



      <LoaderSpin isVisible={isSpinnerVisible} />


      {/* <div className="filter_results_mask d-flex align-content-center flex-wrap justify-content-center">
          <div className=" p-4 text-light text-center">
            <p className="fs-3">
              Applica i filtri selezionati per aggiornare.</p>
            <p className="fs-3 mt-4">
              Oppure, resetta i filtri per riportarli allo stato coerente con i risultati mostrati.</p>
          </div>
        </div> 
        Questa sarebbe la maschera da sovrapporre ai risultati, credo che dovremmo trasformarla in un componente a cui passare un parametro acceso/spento in base alla coerenza tra filtri e risultati
        */}

    </Container >

  );
};

export default MyActivities;