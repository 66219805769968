import PropTypes from "prop-types"
import moment from "moment"

export const DateCell = (props, isTimeStamp) => { 
  const propsValue = props.dataItem[props.field];
  let formattedValue = '';
  if (isTimeStamp) {
    if (propsValue) {
      formattedValue = moment(propsValue).format("DD/MM/Y - HH:mm:ss");
    }
  } else if (propsValue) {
      formattedValue = moment(propsValue).format("DD/MM/Y");
    }
  return <td>{formattedValue}</td>
}

DateCell.propTypes = {
  dataItem: PropTypes.array,
  field: PropTypes.string
}

export default DateCell;