export  const AtlanCell = (props) => {
return (
  <td>
    <a
      style={{ cursor: "pointer" }}
      className="fw-bold text-decoration-underline"
      onClick={(event) => {
        if (props.dataItem.typeHandle)
          window.open(
            process.env.REACT_APP_ATLAN_PRE +
              props.dataItem.typeHandle +
              process.env.REACT_APP_ATLAN_POST,
            "_blank"
          );
      }}
    >
      {props.dataItem.productName}
    </a>
  </td>
);
}



export default AtlanCell;

