import PropTypes from "prop-types";
import { Progress } from "reactstrap";

const ProgressBar = (props) => {
  const progressToggle = props.isVisible ? "show" : "d-none";
  const progressValue = props.value || 0;

  return (
    <>
      <div data-testid="progress-bar" className={`modal-backdrop fade ${progressToggle}`}>
        <div className="centered-container">
            <Progress
              className="my-3"
              animated
              color="warning"
              value={progressValue}
            />
        </div>
      </div>
    </>
  );
};

ProgressBar.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
};

export default ProgressBar;
