import { createSlice } from "@reduxjs/toolkit";
import { openAlert } from "../DataQualityStatus/DataQualitySlice";

const initialState = {
  wsModule: "",
  isSocketOpen: false,
};

export const WebSocketSlice = createSlice({
  name: "webSocket",
  initialState,
  reducers: {
    setWsString: (state, action) => {
      state.wsModule = action.payload;
    },
    setIsSocketOpen: (state, action) => {
      state.isSocketOpen = action.payload;
    },
  },
});

export const { setWsString, setIsSocketOpen } = WebSocketSlice.actions;

export const connectToWs = () => async (dispatch, getState) => {
  const state = getState();
  const isSocketOpen = state.webSocketReducer.isSocketOpen;

  if (isSocketOpen) return;

  // const user = JSON.parse(localStorage.getItem("userInfo"));

  // const accessToken = user.access_token;
  // const webSocket = new WebSocket(`wss://devoverx-api.elbassicurazioni.it/api/v1/notifications/ws?token=${accessToken}`);

  const webSocket = new WebSocket(
    "wss://devoverx-api.elbassicurazioni.it/api/v1/notifications/ws"
  );

  webSocket.onopen = () => {
    console.log("WebSocket connection opened");
    dispatch(setIsSocketOpen(true));
  };

  webSocket.onmessage = (event) => {
    console.log("WebSocket message received");
    console.log("Event", event);
    console.log(
      "Local Storage Request IDs",
      JSON.parse(localStorage.getItem("requestIds"))
    );
    const requestIds = JSON.parse(localStorage.getItem("requestIds"));

    const requestIdObject = requestIds.find(
      (item) => item.requestId == JSON.parse(event.data).requestId
    );

    if (requestIdObject && JSON.parse(event.data).operation.status == "SUCCESS") {
      dispatch(
        openAlert({
          message: `Il file relativo alla versione ${requestIdObject.versionId} è stato creato, clicca sul banner per scaricarlo, oppure vai sul download manager.`,
          type: "success",
          requestId:  requestIdObject.requestId,
          clickable: true,
        })
      );    
    } else if (requestIdObject && JSON.parse(event.data).operation.status != "SUCCESS") {
      dispatch(
        openAlert({
          message: `Errore durante la creazione del file relativo alla versione ${requestIdObject.versionId}, la preghiamo di riprovare più tardi.`,
          type: "error",
        })
      );
    }
  };

  webSocket.onerror = () => {
    console.log("WebSocket connection error");
    dispatch(setIsSocketOpen(false));
  };

  webSocket.onclose = () => {
    console.log("WebSocket connection closed");
    dispatch(setIsSocketOpen(false));
  };

  return dispatch(setWsString("Ho aperto il webSocket!"));
};

export default WebSocketSlice.reducer;
